import React                   from "react";
import JsonPath                from "jsonpath";
import { getObjectPath }       from "../../../../utils/layoutUtils";
import { getHumanized }        from "../../../../utils/schemaUtils";
import { retryableErrorCodes } from "../../../Message/Msg";
import { TableFooter }         from "../../../Table";
import { Table }               from "../../../Table";
import { TableContainer }      from "../../../Table";
import { TableProvider }       from "../../../Table";

export const BulkTable = React.forwardRef<any, any>(function BulkTable(props, ref) {
  const { columns, data, actions, onPage, currentPage, pageCount } = props;
  const getCellValue = (row, column) => {
    const normColumn = /^\$/.test(column) ? column : `$.${column}`;
    const newRow = { ...row, scope: { ...row.scope, navLink: getObjectPath({ __typename: row.scope?.__typename ?? "", objectId: row.scope?.objectId }) } };
    if (newRow.__typename === "PhoneMessage" && column === "errorCode") {
      return retryableErrorCodes[ newRow[ column ] ]?.message;
    }

    return getHumanized(newRow.__typename, column, JsonPath.value(newRow, normColumn));
  };

  return <TableProvider
    total={data.length}
    data={data}
    actions={actions}
    classId={"Contact"}
  >
    <TableContainer>
      <Table
        columns={columns}
        clickableRow={false}
        getCellValue={getCellValue}
      />
      {pageCount > 1 &&
        <TableFooter
          defaultPage={currentPage}
          count={pageCount}
          onChange={(page) => onPage(page)}
        />
      }
    </TableContainer>
  </TableProvider>;
});

