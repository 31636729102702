import React                                               from "react";
import { Fields }                                          from "@relcu/gql-query-builder";
import { ButtonVariants }                                  from "@relcu/ui";
import { ButtonSizes }                                     from "@relcu/ui";
import { Box }                                             from "@relcu/ui";
import { Button }                                          from "@relcu/ui";
import { PointerListFieldProps as IPointerListFieldProps } from "@relcu/ui";
import { PointerListField as BasePointerListField }        from "@relcu/ui";
import { usePointerFilters }                               from "../PointerField";
import { usePointerListField }                             from "./usePointerListField";

export interface PointerListFieldProps extends IPointerListFieldProps {
  fields?: Fields;
}

export const PointerListField = React.memo<Omit<PointerListFieldProps, "searchText" | "options">>(function PointerListField(props) {
  const filters = usePointerFilters(props.filters);
  const { pageInfo, handleLoadMore, setSelectedIds, options, q, setQ, loading } = usePointerListField({ ...props, ...filters });
  const { targetClass, ...otherProps } = props;

  return (
    <BasePointerListField
      {...otherProps}
      onLoadMoreHandler={
        pageInfo?.hasNextPage &&
        <Box container justify={"center"} style={{ position: "sticky", bottom: 0, background: "white" }}>
          <Button size={ButtonSizes.Small} variant={ButtonVariants.Ghost} onClick={handleLoadMore}>Load more</Button>
        </Box>
      }
      onSelectedFilter={(selected) => {
        setSelectedIds((selected as any).map(s => s.objectId));
      }}
      options={options}
      searchText={q}
      onType={setQ}
      loading={loading}
      thumbnail={true}
    />
  );
});
