import React            from "react";
import { FC }           from "react";
import { useRef }       from "react";
import { useCallback }  from "react";
import { useFormState } from "@relcu/final-form";
import { Button }       from "@relcu/rc";
import { Typography }   from "@relcu/rc";
import { useBlocker }   from "@relcu/react-router";
import { ModalFooter }  from "@relcu/ui";
import { ModalBody }    from "@relcu/ui";
import { Modal }        from "@relcu/ui";
import { modal }        from "@relcu/ui";
import { omit }         from "@relcu/ui";

export const DirtyDialogController: FC<any> = React.memo(function DirtyDialogController(props) {
  const isOpened = useRef(false);
  const { dirty, submitting, dirtyFields } = useFormState({
    subscription: {
      dirty: true,
      submitting: true,
      dirtyFields: true
    }
  });
  const fields = omit(dirtyFields, ["from", "to", "loanProposal", "lead"]);
  const handleBlocker = useCallback((tx) => {
    if (!submitting && !isOpened.current) {
      isOpened.current = true;
      const { destroy } = modal(DirtyDialog, {
        onClose: () => {
          isOpened.current = false;
          destroy();
        },
        onConfirm: () => {
          isOpened.current = false;
          tx.retry();
          destroy();
        }
      });
    }
  }, [dirty, submitting, dirtyFields]);

  useBlocker(handleBlocker, Object.keys(fields).length > 0);

  return null;
});

export const DirtyDialog = function DirtyDialog(props) {
  return (
    <Modal
      className={"unsaved-changes-modal"}
      title={"Unsaved changes"}
      open={true}
      onClose={props.onClose}
      closeIcon={true}
      variant={"small"}
    >
      <ModalBody gap={"XS"} container direction={"column"} flex={"1 1 auto"} justify={"center"} alignItems={"center"}>
        <Typography variant={"base14"} weights={"medium"} lineHeight={"lh24"}>
          You have unsaved changes that will be lost if you proceed.
        </Typography>
        <Typography variant={"small12"} style={{ color: "var(--rc-text-secondary)" }}>
          Are you sure you want to continue without saving?
        </Typography>
      </ModalBody>
      <ModalFooter>
        <Button type="button" size={"sm"} onClick={props.onClose} appearance="text">CANCEL</Button>
        <Button size={"sm"} onClick={props.onConfirm}>CONTINUE</Button>
      </ModalFooter>
    </Modal>
  );
};
