import React                    from "react";
import { useImperativeHandle }  from "react";
import { useRef }               from "react";
import { useEffect }            from "react";
import { useLazyQuery }         from "@apollo/client";
import { gql }                  from "@apollo/client";
import { LazyQueryResultTuple } from "@apollo/client/react/types/types";
import { CircularLoader }       from "@relcu/ui";
import { RenderEmailVariables } from "./__types__/RenderEmail";
import { RenderEmail }          from "./__types__/RenderEmail";
import Email                    from "./Email";

interface BaseProps {
  subject?: string;
  template?: string;
  isTemplateRequired?: boolean;
  ids?: Record<string, string>;
  toEmails?: string[];
  onRender?: (o: { html: string, subject: string }) => void;
  onError?(error);
  onSuccess?(data);
}
interface EmailRendererIdProps extends BaseProps {
}
interface EmailRendererProps extends BaseProps {
  template: string;
}
export interface EmailRendererRef {
  html: string;
  text: string;
  subject: string;
  visualizer: HTMLDivElement;
}
export const useMailRenderer = (): LazyQueryResultTuple<RenderEmail, RenderEmailVariables> => {
  const [load, result] = useLazyQuery<RenderEmail, RenderEmailVariables>(RENDER_EMAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: { errorPolicy: "ignore" }
  });
  return [load, result];
};
export const EmailRenderer = React.forwardRef<EmailRendererRef, EmailRendererProps | EmailRendererIdProps>(function EmailRenderer({ onRender, subject, template, toEmails, onError, onSuccess, ids, isTemplateRequired = true, ...fields }, ref) {
  const [load, result] = useMailRenderer();
  const emailRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const { contactId, scopeId, loanProposalId, fromId, templateId, ...rest } = ids;
    if (isTemplateRequired && !template && !templateId) {
      return;
    }
    let input: RenderEmailVariables["input"] = {
      from: fromId,
      to: toEmails,
      ...rest,
      contact: contactId || undefined,
      scope: scopeId || undefined,
      proposal: loanProposalId,
      subject: subject
    };

    if (!isTemplateRequired && !template) {
      input.template = "  ";
    } else if (template) {
      input.template = template;
    } else if (templateId) {
      input.templateId = templateId;
    }
    load({ variables: { input } }).then((re) => {
      onRender?.(re.data.renderMail);
    });
  }, [template, toEmails, subject, ids, isTemplateRequired]);

  useImperativeHandle(ref, () => {
    return {
      get html() {
        return result.data?.renderMail?.html;
      },
      get text() {
        return result.data?.renderMail?.text;
      },
      get subject() {
        return result.data?.renderMail?.subject;
      },
      get visualizer() {
        return emailRef.current;
      }
    };
  }, [result.data, emailRef.current]);

  useEffect(() => {
    result.error && onError?.(result.error);
    result.data && onSuccess?.(result.data);
  }, [onError, result]);

  return (
    <>
      {
        result.loading ? <CircularLoader justify={"center"} alignItems={"start"}
                                         style={{ minHeight: `${emailRef.current?.offsetHeight}px` }}/> :
          <Email ref={emailRef} template={result.data?.renderMail?.html || result.error?.message}/>
      }
    </>
  );
});
const RENDER_EMAIL_QUERY = gql`
  query RenderEmail($input:MailRenderInput){
    renderMail(input:$input)
  }
`;
