import { useMemo }           from "react";
import React                 from "react";
import { FC }                from "react";
import { BoxComponentProps } from "../../Box";
import { BaseArrayField }    from "../BaseField";
import { BaseFieldProps }    from "../BaseField";
import EmailsEditField       from "./EmailsEditField";
import EmailsReadField       from "./EmailsReadField";

export type EmailsFieldProps = BaseFieldProps & BoxComponentProps & {
  options: { label: string, value: string }[]
};

export const EmailsField: FC<EmailsFieldProps> = React.memo(function EmailsField(props) {

  return (
    <BaseArrayField
      {...props}
      defaultValue={useMemo(() => [{ type: props.options[ 0 ]?.value }], [props.options])}
      readView={<EmailsReadField/>}
      editView={<EmailsEditField/>}
    />
  );
});
EmailsField.defaultProps = {
  options: []
};
