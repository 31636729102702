import { Box }              from "@relcu/ui";
import React                from "react";
import { FC }               from "react";
import { omit }             from "@relcu/ui";
import { JsonSectionProps } from "@relcu/ui";
import { PermissionField }  from "@relcu/ui";
import { Section }          from "@relcu/ui";
import { useSchemaField }   from "../../useSchemaField";

export const PermissionsSection: FC<JsonSectionProps> = React.memo(function PermissionsSection(props) {
  const { title, editable, onEdit, onClear, ...p } = props;
  const { options } = useSchemaField("EmailTemplate", "type");
  const availableEmailTemplates = options.map(option => {
    return {
      name: option.value,
      label: `Allow ${option.label.toLowerCase()}`,
      readStateLabel: option.label
    }
  })


  return <Section
    view={props.view}
    title={title}
    editable={editable}
    container
    direction={"column"}
    gap={"XS"}
    onEdit={onEdit}
    onClear={onClear}
    {...p}>
    <Box container gap={"L"}>
      <PermissionField
        parse={(value) => {
          if (value.create == false && value.share == true) {
            value.share = false;
          }
          return omit(value, ["__typename"]);
        }}
        format={(value) => {
          return omit(value, ["__typename"]);
        }}
        view={["read", "disabled"].includes(props.view) ? "read" : "write"}
        name={"dataTableView"}
        label={"Data table views"}
        description={"Choose from the options to set up user permissions for data tables.\n\"Allow to manage\" is the permission to create, configure, and edit.\n\"Allow to share\" is the permission to share a data table with other users."}
        options={[
          {
            "name": "create",
            "label": "Allow to manage",
            "readStateLabel": "Manage"
          },
          {
            "name": "share",
            "label": "Allow to sharing",
            "readStateLabel": "Share"
          }
        ]}
      />
      <PermissionField
        parse={(value) => {
          if (value.create == false && value.share == true) {
            value.share = false;
          }
          return omit(value, ["__typename"]);
        }}
        format={(value) => {
          return omit(value, ["__typename"]);
        }}
        view={["read", "disabled"].includes(props.view) ? "read" : "write"}
        name={"bulkPermissions"}
        label={"Bulk messaging"}
        description={"Choose from the options to set up user permissions for bulk messaging.\n\"Allow bulk email\" is the permission to send bulk emails."}
        options={[
          {
            "name": "email",
            "label": "Allow bulk email",
            "readStateLabel": "Email"
          },
          {
            "name": "sms",
            "label": "Allow bulk sms",
            "readStateLabel": "SMS"
          }
        ]}
      />
      <PermissionField
        parse={(value) => {
          if (value?.create == false && value?.share == true) {
            value.share = false;
          }
          if (value) {
            return omit(value, ["__typename"]);
          }
          return {};
        }}
        format={(value) => {
          if (value) {
            return omit(value, ["__typename"]);
          }
          return {};
        }}
        view={["read", "disabled"].includes(props.view) ? "read" : "write"}
        name={"emailTemplateEditorPermissions"}
        label={"Email template editor"}
        description={"Choose from the options to set up user permissions for template."}
        options={availableEmailTemplates}
      />
    </Box>
  </Section>;
});
