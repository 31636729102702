import React                           from "react";
import { LinkProps as RouteLinkProps } from "@relcu/react-router";
import { NavLink }                     from "@relcu/react-router";
import { classNames }                  from "../..";
import { applyBoxStyles }              from "../Box";
import { BoxComponentProps }           from "../Box";

export enum LinkColors {
  Primary = "primary",
}

export type LinkProps = BoxComponentProps & RouteLinkProps & {
  color?: LinkColors
  end?: boolean;
};

export function Link(props: LinkProps) {
  let properties = applyBoxStyles(props);
  const { className, children, to, color,end, ...p } = properties;
  const classes = classNames(LinkClasses.Link, {
    [ LinkClasses.Primary ]: color == LinkColors.Primary
  }, className);

  return (
    <NavLink end={end} to={to} className={classes} {...p}>
      {children}
    </NavLink>
  );
}
export enum LinkClasses {
  Link = "link",
  Primary = "link--primary",
  Active = "active"
}
