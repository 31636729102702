import { useSource }        from "@relcu/ui";
import React                from "react";
import { FC }               from "react";
import { useMemo }          from "react";
import { useCallback }      from "react";
import { ButtonColors }     from "@relcu/ui";
import { Tooltip }          from "@relcu/ui";
import { ButtonSizes }      from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { Alignment }        from "@relcu/ui";
import { IActionCellProps } from "@relcu/ui";
import { getDefaultPhoneNumberForCall } from "../../../../../utils/helpers";
import { getBorrowerName }              from "../../../../../utils/helpers";
import { getPrimaryBorrower }           from "../../../../../utils/helpers";
import { getField }                     from "../../../../../utils/schemaUtils";
import { usePermissions }               from "../../../../AccessControl";
import { usePhone }                     from "../../../../usePhone";
import { useViewerPhoneLines }          from "../../../../useViewerPhoneLines";

export const CallAction: FC<IActionCellProps> = React.memo(function CallAction(props) {
  const { rowData, tooltip, bulkPermissions, singlePermissions, ...p } = props;
  const { states } = getField("Lead", "leadStatus");
  const phone = usePhone();
  const {$viewer} = useSource()
  const { fromNumbers, hasMicrophoneIssue } = useViewerPhoneLines();
  const { canUpdate } = usePermissions(rowData);
  let primaryBorrower = rowData.__typename == "Lead" ? getPrimaryBorrower(rowData) : rowData;
  let number = getDefaultPhoneNumberForCall(primaryBorrower?.contact?.phones, "Contact");
  const isInCall = useMemo(() => {
    return phone?.hybridCalls?.initialDisplayCall.scopeId == rowData.objectId;
  }, [phone?.hybridCalls?.initialDisplayCall, rowData.objectId]);
  const disabled = states[ rowData?.leadStatus?.status ]?.not_contact || !canUpdate || (!!phone.active && !isInCall) || (rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length) || !fromNumbers.length || !number || number?.callOptOut || !primaryBorrower?.contact?.phones?.length || hasMicrophoneIssue || ($viewer?.onCall && !isInCall);
  const onCall = useCallback((e, rowData) => {
    e.stopPropagation();
    if (isInCall) {
      if (phone.direction == "incoming") {
        phone.active.reject();
      } else {
        phone.active.drop();
      }
    } else if (!phone.active && !disabled) {
      let borrowerName = getBorrowerName(primaryBorrower);
      if (number && !number.callOptOut) {
        phone.call({
          from: fromNumbers[ 0 ].value,
          to: number.number,
          scopeId: rowData.objectId,
          scopeName: rowData.objectName,
          scopeClassName: rowData.__typename,
          contactId: primaryBorrower.contact.objectId,
          contactName: borrowerName
        });
        // navigate(`/lead/${rowData.objectId}`);
      }
    }

  }, [phone, fromNumbers, number, isInCall, disabled, primaryBorrower]);
  return <Tooltip title={tooltip} alignment={Alignment.Bottom}>
    <Button
      style={{ pointerEvents: "all" }}
      icon={isInCall ? "call_end" : "call"}
      color={isInCall ? ButtonColors.Error : ButtonColors.Success}
      onlyIcon
      size={ButtonSizes.Small}
      disabled={disabled}
      onClick={(e) => onCall(e, rowData)}/>
  </Tooltip>;
});
