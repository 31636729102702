import { useQuery }                              from "@apollo/client";
import { useReactiveVar }                        from "@apollo/client";
import { isEmpty }                               from "../../../../utils/helpers";
import { GetMessageTemplatesVariables }          from "../../../__types__/GetMessageTemplates";
import { GetMessageTemplates }                   from "../../../__types__/GetMessageTemplates";
import { GET_MESSAGE_TEMPLATES }                 from "../../../operations.graphql";
import { useMessageTemplateQueryParams }         from "../../../useMessageTemplateGqlParams";
import { messageDraftsVar }                      from "../../../../reactiveVars";

export function useMessageTemplates() {
  const draft = useReactiveVar(messageDraftsVar);
  const whereParams = useMessageTemplateQueryParams({
    type: "sms",
    considerOwner: true,
    ...(isEmpty(draft) && { isLoanProposal: false })
  });
  const query = useQuery<GetMessageTemplates, GetMessageTemplatesVariables>(GET_MESSAGE_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      ...whereParams
    }
  });

  return {
    get templates() {
      const { data: { phoneMessageOwnerTemplates: { edges: ownerEdges = [] } = {}, phoneMessageNoOwnerTemplates: { edges: noOwnerEdges = [] } = {} } = {} } = query;
      return [...ownerEdges, ...noOwnerEdges];
    },
    get loaded() {
      return !!query.data;
    }
  };
}
