import React              from "react";
import { FC }             from "react";
import { Stack }          from "@relcu/rc";

export const SectionHeader: FC<any> = React.memo(function SectionHeader(props) {
  return <Stack
    {...props}
    className={"mail-template-section-header"}
    justifyContent={"space-between"}
  />;
});
