import { useLazyQuery }    from "@apollo/client";
import { gql }             from "@apollo/client";
import { useField }        from "@relcu/final-form";
import { useForm }         from "@relcu/final-form";
import { FieldCell }       from "@relcu/rc";
import { useSource }       from "@relcu/ui";
import { useMemo }         from "react";
import { useContext }      from "react";
import { useState }        from "react";
import { useCallback }     from "react";
import React               from "react";
import { FC }              from "react";
import { Proposal }        from "../../../Proposal";
import { GetPmiVariables } from "./__types__/GetPmi";
import { GetPmi }          from "./__types__/GetPmi";
import { PmiDialog }       from "./PmiDialog";

export const PmiSelectCell: FC = React.memo(function PmiSelectCell() {
  const { getState } = useForm();
  const loanEstimate = useContext(Proposal.Context);
  const { $object: lead } = useSource();
  const form = useForm();
  const [open, setOpen] = useState(false);
  const [getPmi, { data, loading, fetchMore }] = useLazyQuery<GetPmi, GetPmiVariables>(GET_PMI, {
    notifyOnNetworkStatusChange: true
  });
  const pmiRates = data?.getPmiRates;
  const pmiInput = useCallback((): GetPmiVariables["input"] => {
    const { values } = getState();
    const borrowers = lead.members.filter((members) => ["borrower", "co_borrower"].includes(members.type)).map(({
      type,
      creditScore,
      employmentStatus
    }) => ({
      type,
      creditScore: (type == "borrower" ? values.fico : values.fico1),
      isSelfEmployed: employmentStatus === "self_employed"
    }));
    //todo Review this part do pmi need to be depended on pricing engine.
    const normLoanProgram = (values.loanProduct || "").toLowerCase();
    let pmiLoanProgram = "None";
    if (normLoanProgram.includes("home") && normLoanProgram.includes("ready")) {
      pmiLoanProgram = "Fannie Mae HomeReady";
    } else if (normLoanProgram.includes("home") && (normLoanProgram.includes("poss") || normLoanProgram.includes("possible"))) {
      pmiLoanProgram = "Freddie Mac HomePossible";
    }
    return {
      loanAmount: Math.round(values.totalLoanAmount),
      dti: Math.round(values.dti * 100) / 100,
      loanPurpose: loanEstimate.loanPurpose,
      loanProgram: pmiLoanProgram,
      loanTerm: parseInt(values.loanTerm),
      firstTimeHomeBuyer: values.firstTimeHomeBuyer,
      borrower: borrowers.find(({ type }) => type === "borrower"),
      coBorrower: borrowers.find(({ type }) => type === "co_borrower"),
      property: {
        type: loanEstimate.propertyType,
        occupancy: loanEstimate.propertyOccupancy,
        value: values.propertyValue,
        propertyAddress: {
          state: loanEstimate.propertyState,
          zipCode: loanEstimate.propertyZipCode
        }
      }
    };

  }, [getState, loanEstimate, lead.members]);
  const getPmiData = (pmiRates) => {
    return {
      ...pmiRates,
      rates: Object.fromEntries(Object.entries<any>(pmiRates?.rates || {}).map(([key, r]) => {
        return [key, [...r].sort((a, b) => a.premium - b.premium)];
      }))
    };
  };
  const sortedPmiData = useMemo(() => getPmiData(pmiRates), [pmiRates]);
  const { input: { value: selectedPmiId } } = useField("pmiCompany", { subscription: { value: true } });
  const { input: { value: selectedPmiType } } = useField("pmiType", { subscription: { value: true } });

  const [selectedId, setSelectedId] = useState<string>(selectedPmiId);
  const [selectedType, setSelectedType] = useState<string>(selectedPmiType);
  const selectedPmi = useMemo(() => {
    return pmiRates?.rates[ selectedType ]?.find(fee => fee.providerId == selectedId);
  }, [selectedId, selectedType, pmiRates]);
  const onApply = useCallback(() => {
    form.change("pmiCompany", selectedId);
    form.change("pmiType", selectedType);
    form.change("pmi", selectedPmi?.premium);
    setOpen(false);
  }, [selectedId, selectedType, selectedPmi, setOpen]);

  const onOpen = useCallback(async () => {
    const { data } = await getPmi({ variables: { input: pmiInput() } });
    const pmiRates = data?.getPmiRates;
    const sortedPmiData = getPmiData(pmiRates);

    if (!selectedId && Object.keys(sortedPmiData?.rates || {}).length) {
      const monthly = !!sortedPmiData.rates.monthly;
      const keys = Object.keys(sortedPmiData?.rates);
      const selected = monthly
        ? sortedPmiData.rates.monthly.find(r => !r.errorMessage)
        : sortedPmiData.rates[ keys[ 0 ] ].find(r => !r.errorMessage);
      if (selected) {
        let selectedType;
        let selectedId;
        selectedId = selected.providerId;
        if (monthly) {
          selectedType = "monthly";
        } else {
          selectedType = keys[ 0 ];
        }
        setSelectedType(selectedType);
        setSelectedId(selectedId);
        const selectedPmi = pmiRates?.rates[ selectedType ]?.find(fee => fee.providerId == selectedId);
        form.change("pmiCompany", selectedId);
        form.change("pmiType", selectedType);
        form.change("pmi", selectedPmi.premium);
      }
    } else {
      setOpen(true);
    }
  }, [pmiInput, selectedId]);

  const reFetch = useCallback(async (pmiPartners, pmiProducts) => {
    await fetchMore({
      variables: {
        input: {
          ...pmiInput(),
          pmiProducts,
          pmiPartners
        }
      }
    });
  }, [fetchMore]);
  const warning = useMemo(() => {
    return pmiRates?.rates && Object.keys(pmiRates.rates).some(key => {
      const index = pmiRates.rates[ key ]?.findIndex((provider) => provider.errorMessage);
      return index > -1;
    });
  }, [pmiRates]);
  const error = useMemo(() => {
    return pmiRates?.status?.code == 400;
  }, [pmiRates]);

  return <>
    <PmiDialog open={open} onClose={() => setOpen(false)} reFetch={reFetch} loading={loading}
               pmiRates={sortedPmiData} setSelectedType={setSelectedType} setSelectedId={setSelectedId}
               onApply={onApply} selectedId={selectedId} selectedPmi={selectedPmi} selectedType={selectedType}/>
    <FieldCell.PmiCell
      helperText={error ? "Failed to get PMI rates." : (warning ? "There are options that failed to load." : "")}
      status={error ? "error" : (warning ? "warning" : "info")} required
      loading={loading} onOpen={onOpen} name={"pmiCompany"}/>
  </>;
});

export const GET_PMI = gql`
  query GetPmi($input: PmiRatesArgs!)  {
    getPmiRates(input:$input) @connection(key: "pmiRatesBeta",filter: ["input"]) {
      errors{
        code
        message
        providerId
      }
      status{
        code
        message
      }
      rates
    }
  }
`;
