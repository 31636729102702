import { useMemo }                        from "react";
import { useQuery }                       from "@apollo/client";
import { gql }                            from "@apollo/client";
import { PhoneMessageTemplateWhereInput } from "../types/graphql-global-types";
import { MessageTemplateUser }            from "./__types__/MessageTemplateUser";

type  PhoneMessageTemplateSubscriptionWhereInput = any
export interface MessageTemplateGqlParams {
  type?: string,
  considerOwner?: boolean,
  isLoanProposal?: boolean,
  forAutomation?: boolean
}

// function getCommon(type?: string, isLoanProposal?: boolean, considerOwner?: boolean) {
//   const query: /*PhoneMessageTemplateSubscriptionWhereInput | */PhoneMessageTemplateWhereInput = {
//     disabled: {
//       notEqualTo: true
//     }
//   };
//   if (type) {
//     query.type = {
//       equalTo: type
//     };
//   }
//   if (isLoanProposal != undefined) {
//     query.loanProposal = {
//       equalTo: isLoanProposal
//     };
//   }
//   if (considerOwner) {
//     query.OR = [{
//       owner: {
//         exists: false
//       }
//     }];
//   }
//   return query;
// }

function getCommon(type?: string, isLoanProposal?: boolean, considerOwner?: boolean) {
  const query: /*PhoneMessageTemplateSubscriptionWhereInput | */PhoneMessageTemplateWhereInput = {
    disabled: {
      notEqualTo: true
    },
    forAutomation: {
      notEqualTo: true
    }
  };
  if (type) {
    query.type = {
      equalTo: type
    };
  }
  if (isLoanProposal != undefined) {
    query.loanProposal = {
      equalTo: isLoanProposal
    };
  }
  if (considerOwner) {
    query.owner = {
      exists: false
    };
  }
  return query;
}

const MESSAGE_TEMPLATE_USER = gql`
  query MessageTemplateUser {
    viewer {
      user {
        id
      }
    }
  }
`;

export function useMessageTemplateQueryParams({ type, considerOwner, isLoanProposal }: MessageTemplateGqlParams): {
  owner: PhoneMessageTemplateWhereInput,
  noOwner: PhoneMessageTemplateWhereInput,
  skipOwner: boolean,
  skipNoOwner: boolean,
} {
  const { data: { viewer: { user } } } = useQuery<MessageTemplateUser>(MESSAGE_TEMPLATE_USER, {
    fetchPolicy: "cache-only"
  });
  return useMemo(() => {
    const noOwner = getCommon(type, isLoanProposal, considerOwner);
    const result: any = {
      noOwner
    };

    if (considerOwner) {
      const withOwner = { ...noOwner };
      withOwner.owner = {
        have: {
          id: {
            equalTo: user.id
          }
        }
      };
      result.owner = withOwner;
    } else {
      result.skipOwner = true;
    }

    return result;

  }, [type, isLoanProposal, considerOwner]);
}
