import React                          from "react";
import { FC }                         from "react";
import { useContext }                 from "react";
import { ReactNode }                  from "react";
import { FormSpy }                    from "@relcu/form";
import { FormSummary }                from "@relcu/ui";
import { format }                     from "../../../../../../utils/helpers";
import { RateContext }                from "../Rate/RateProvider";
import { MONTHLY_PAYMENT_SUM_FIELDS } from "../RateConditions";

type FunctionOrReactNode = ReactNode | ((value: string | number) => ReactNode)
export const MonthlyPaymentSum: FC<{ children?: FunctionOrReactNode, prefix?: string }> = React.memo(function MonthlyPaymentSum(props) {
  const { children, prefix = "$" } = props;
  const { selectedPmi, isMonthlyPmi } = useContext(RateContext);

  return <FormSpy subscription={{ values: true }}>
    {({ values }) =>
      <FormSummary data={{
        ...values,
        pmi: isMonthlyPmi ? selectedPmi?.premium || 0 : 0
      }} fields={MONTHLY_PAYMENT_SUM_FIELDS}>
        {
          (value) => {
            return children instanceof Function
              ?
              <>{children(value)}</>
              :
              <>{prefix || ""}{format(value, 2)}</>;
          }
        }
      </FormSummary>
    }
  </FormSpy>;
});
