import { useNavigate }     from "@relcu/react-router";
import { CircularLoader }  from "@relcu/ui";
import { useState }        from "react";
import { useEffect }       from "react";
import React               from "react";
import { useJqlLazyQuery } from "../../Layout/Jql";

export const Five9 = React.memo(function Five9View(props) {
  const [fetchConference, { data, stopPolling }] = useJqlLazyQuery({
    operation: "conference",
    variables: {
      id: {
        name: "id",
        type: `ID!`
      }
    },
    fields: [
      "id",
      "objectId",
      "navigate",
      {
        scope: ["id", "objectId"]
      }
    ]
  }, {
    operationName: "GetFive9Call",
    errorPolicy: "ignore",
    onError: (e) => {
      console.info(e);
    },
    returnPartialData: true
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const interactionApi = window.Five9.CrmSdk.interactionApi();
    interactionApi.subscribe({
      callAccepted: (params) => {
        fetchConference({
          pollInterval: 500,
          context: { errorPolicy: "ignore" },
          variables: { id: params.callData.callId }
        }).catch((e) => {
          console.error(e);
        });
      },
      callEnded: () => {
        stopPolling();
      },
      interactionOpened: () => {
      },
      callStarted: () => {
      },
      callFinished: () => {
      }
    });
  }, []);

  useEffect(() => {
    if (data && data.conference && data.conference.scope && data.conference.navigate) {
      stopPolling();
      navigate(`/lead/${data.conference.scope.objectId}`);
    }
  }, [data]);

  return (
    <>
      <iframe
        frameBorder="0"
        onLoad={() => setLoading(false)}
        src="https://app.five9.com/clients/integrations/adt.main.html?f9verticalthreshold=500px"
        style={{ position: "relative", height: "100%", width: "100%" }}/>
      {loading &&
        <CircularLoader justify={"center"} style={{ right: "50%", position: "absolute" }} alignSelf={"center"}/>}
    </>
  );
});
