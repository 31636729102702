import React                  from "react";
import { FC }                 from "react";
import { Label, MultiSelect } from "@relcu/ui";
import { BackDropLoader }     from "@relcu/ui";
import { SearchInput }        from "@relcu/ui";
import { Box }                from "@relcu/ui";
import { ModalProps }         from "@relcu/ui";
import { ModalBody }          from "@relcu/ui";
import { Modal }              from "@relcu/ui";
import { EmptyList }          from "@relcu/ui";
import { SearchModalClasses } from "./SearchModalClasses";
import { SearchResult }       from "./SearchResult";
import { useAtlasSearch }     from "./useAtlasSearch";
import { useSearch }          from "./useSearch";
import "./search-dialog.css";

export interface SearchDialogProps extends Partial<ModalProps> {
  onClose?(e?),
}
export const SearchModal: FC<SearchDialogProps> = React.memo((props) => {
  const mongoSearch = useSearch(props.onClose);
  const atlasSearch = useAtlasSearch(props.onClose);
  const search = window.__CONFIG__.legacySearch ? mongoSearch : atlasSearch;
  return (
    <Modal title={"Search"} onClose={props.onClose} open={true} closeIcon={true}
           top={"100px"}
           disableBackdropClick={false}
           variant={"middle"}>
      <ModalBody className={SearchModalClasses.Container} flexGrow={1} gap={"XS"} container direction={"column"}>
        <Box container direction={"column"} flex={"1 1 auto"} gap={"L"}
             className={SearchModalClasses.Body}>
          <Box container direction={"column"} gap={"XS"}>
            <SearchInput
              flexGrow={1}
              clearable
              placeholder={"Search"}
              value={search.value}
              style={{ padding: 0 }}
              autoFocus={true}
              onChange={search.setValue}/>
            <Box container alignItems={"center"}>
              <Label>Type</Label>
              <MultiSelect
                filterable={false}
                getOptionValueByKey={true}
                headerProps={{
                  showBorder: false
                }}
                showSelectedCount
                showSelectionActions
                placeholder="Choose type"
                alignSelf={"start"}
                value={search.classNames}
                optionKey={"value"}
                onChange={search.setClassNames}
                options={search.visibleSchemas}
                flexGrow={0}
                width={200}
              />
            </Box>
          </Box>
          {
            // !search.isEmpty && !search.loading &&
            <SearchResult
              onRowClick={search.onRowClick}
              classNames={search.classNames}
              getObjects={search.getObjects}
              getCount={search.getCount}
              onPage={search.onPage}
            />
          }
          {
            search.isEmpty &&
            <EmptyList
              justify={"center"}
              flexBasis={"300px"}
              title={"No search results"}
              content={"Please type another keyword and hit enter"}
              icon={"search"}/>
          }
          {
            search.loading &&
            <Box flexBasis={300} style={{ position: "relative" }}>
              <BackDropLoader/>
            </Box>
          }
        </Box>
      </ModalBody>
    </Modal>
  );
});

