import { FormSpy }               from "@relcu/final-form";
import { useSource }             from "@relcu/ui";
import { useMemo }               from "react";
import { useContext }            from "react";
import React                     from "react";
import { FC }                    from "react";
import { useState }              from "react";
import { gql }                   from "@apollo/client";
import { useMutation }           from "@apollo/client";
import { useForm }               from "@relcu/final-form";
import { useAlert }              from "@relcu/ui";
import { FormField }             from "@relcu/rc";
import { Icon }                  from "@relcu/rc";
import { Button }                from "@relcu/rc";
import { Form }                  from "@relcu/rc";
import { Typography }            from "@relcu/rc";
import { Stack }                 from "@relcu/rc";
import { transformNameToLabel }  from "../../../../../../utils/helpers";
import { toFirstLower }          from "../../../../../../utils/helpers";
import { PointerListPicker }     from "../../../../Field/PointerListPicker";
import { PointerPicker }         from "../../../../Field/PointerPicker";
import { PointerPickerProps }    from "../../../../Field/PointerPicker";
import { MailTemplateContext }   from "../Content";
import { SendTestMailVariables } from "./__types__/SendTestMail";
import { SendTestMail }          from "./__types__/SendTestMail";

export interface TemplatePreviewSettingsProps {
  screenType: "mobile" | "desktop";
  onScreenTypeChange(type: "mobile" | "desktop");
}

export const TemplatePreviewSettings: FC<TemplatePreviewSettingsProps> = React.memo(function TemplatePreviewSettings(props) {
  const [send] = useMutation<SendTestMail, SendTestMailVariables>(SEND_TEST_MAIL);
  const { replaceableFieldsSources } = useContext(MailTemplateContext);
  let optionalFields = useMemo(() => replaceableFieldsSources.filter(r => r != "Contact"), [replaceableFieldsSources]);
  const [email, setEmail] = useState();
  const { $viewer } = useSource();
  const form = useForm();
  const { success, error } = useAlert();
  const onSendTestMail = async () => {
    const { subject, content, scope, from, loanProposal } = form.getState().values;
    try {
      await send({
        variables: {
          input: {
            scope,
            subject: subject,
            to: email,
            from: from,
            loanProposalId: loanProposal,
            text: content
          }
        }
      });
      success("Email sent.");
    } catch (e) {
      error(e.message);
    }
  };
  const userFilters = useMemo(() => {
    if ($viewer.role == "loan_officer" || ($viewer.role == "team_manager" && !$viewer.team)) {
      return {
        objectId: { equalTo: $viewer.objectId }
      };
    } else if ($viewer.role == "team_manager") {
      return {
        team: { equalTo: $viewer.team }
      };
    }
    return {};
  }, [$viewer.role]);
  return <Stack direction={"column"} childrenRenderMode={"clone"} className={"template-preview-bar"}
                alignItems={"flex-start"}>
    <Stack.Item alignSelf={"stretch"} className={"template-preview-bar-top"} style={{}}>
      <Stack direction={"column"} childrenRenderMode={"clone"} spacing={24} alignItems={"stretch"}>
        <Typography variant={"base16"} weights={"medium"}>
          Preview settings
        </Typography>
        <Stack.Item alignSelf={"stretch"}>
          <FormField<PointerPickerProps>
            component={PointerListPicker}
            name="to"
            label="To"
            errorMessage={false}
            initialValue={[]}
            properties={{
              style: { width: "100%" },
              labelKey: "objectName",
              valueKey: "objectId",
              fields: ["primaryEmail"],
              targetClass: "Contact"
            }}
          />
          <FormField<PointerPickerProps>
            component={PointerPicker}
            name="from"
            label="From"
            errorMessage={false}
            properties={{
              cleanable: true,
              style: { width: "100%" },
              labelKey: "objectName",
              valueKey: "objectId",
              filters: userFilters,
              targetClass: "User"
            }}
          />
          {
            <FormSpy subscription={{ values: true, initialValues: true }}>
              {
                ({ values, initialValues }) => {
                  return optionalFields.filter(r => values.applyTo.includes(toFirstLower(r))).map((field, index) => {
                    const name = toFirstLower(field);
                    return <FormField<PointerPickerProps>
                      key={index}
                      component={PointerPicker}
                      name={name == "loanProposal" ? name : "scope"}
                      label={transformNameToLabel(field)}
                      errorMessage={false}
                      properties={{
                        cleanable: true,
                        style: { width: "100%" },
                        labelKey: "objectName",
                        valueKey: name == "loanProposal" ? "objectId" : "id",
                        targetClass: field
                      }}
                    />;
                  });
                }
              }
            </FormSpy>
          }
        </Stack.Item>
      </Stack>
    </Stack.Item>
    <Stack.Item alignSelf={"stretch"} className={"template-preview-bar-bottom"}>
      <Stack direction={"column"} childrenRenderMode={"clone"} alignItems={"stretch"}>
        <Typography variant={"base16"} weights={"medium"} lineHeight={"lh20"}>
          Send test email
        </Typography>
        <Form.Group>
          <Form.ControlLabel>Recipient</Form.ControlLabel>
          <Form.Control onChange={setEmail} name="recipient"/>
        </Form.Group>
        <Button disabled={!email} onClick={onSendTestMail} appearance={"text"} startIcon={<Icon type="email"/>}>
          SEND TEST EMAIL
        </Button>
      </Stack>
    </Stack.Item>
  </Stack>;
});

export const SEND_TEST_MAIL = gql`
  mutation SendTestMail($input:MailSendInput){
    sendTestEmail(input:$input){
      id
    }
  }
`;
