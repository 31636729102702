import React            from "react";
import { FC }           from "react";
import { ComposeProps } from "./Compose";
import { Compose }      from "./Compose";

export interface ComposeContainerProps extends ComposeProps {
}
export const ComposeContainer: FC<ComposeContainerProps> = React.memo(function ComposeContainer(props) {
  const { draftId, expand, draftReplyToMessage, onToggle, initialValues, ...rest } = props;


  return <>
    <Compose
      draftId={draftId}
      initialValues={initialValues}
      expand={expand}
      onToggle={onToggle}
      draftReplyToMessage={draftReplyToMessage}
      {...rest}
    />
  </>;
});
