import { ReactElement }       from "react";
import { useState }           from "react";
import { useContext }         from "react";
import React                  from "react";
import { useMemo }            from "react";
import { FC }                 from "react";
import { Alignment }          from "@relcu/ui";
import { ButtonVariants }     from "@relcu/ui";
import { FontIcon }           from "@relcu/ui";
import { Tooltip }            from "@relcu/ui";
import { Button }             from "@relcu/ui";
import { CircularLoader }     from "@relcu/ui";
import { Box }                from "@relcu/ui";
import { classNames }         from "@relcu/ui";
import { BoxComponentProps }  from "@relcu/ui";
import { useSource }          from "@relcu/ui";
import { format }             from "../../../../../../utils/helpers";
import PmiDialog              from "../PmiDialog";
import { RateClasses }        from "../Rate/RateClasses";
import { RateContext }        from "../Rate/RateProvider";
import { RateSelect }         from "../Rate/RateSelect";
import { RatesContext }       from "../RatesProvider";
import { TitleFeeDialog }     from "../TitleFeeDialog/TitleFeeDialog";
import { RatePartnerClasses } from "./RatePartnerClasses";
import { usePmi }             from "./usePmi";
import "./rate-partner.css";

const messages = {
  "warning": "There are options that failed to load.\nReload the failed options from the list to \nchoose the best one.",
  "errorPMI": "Failed to get PMI rates.",
  "errorTitle": "Failed to get Title fees.",
  "selected": "Click on the logo to switch to a different option.",
  "initial": "This is an auto selected rate.\n Click on the logo to switch to \n a different option."
};

export interface RatePartnerSelectProps extends BoxComponentProps {
  option: string;
  partnerImg?: ReactElement;
}

export enum RatePartnerVariant {
  Warning = "warning",
  Error = "error",
}

export const RatePartnerSelect: FC<RatePartnerSelectProps> = React.memo(function RatePartnerSelect(props) {
  const { loading, error, errors, refetch, pmi, option } = usePmi();
  const [openModal, setOpenModal] = useState(false);
  const { className, partnerImg, ...p } = props;
  const classes = classNames(RateClasses.PartnerSelect, {
    [ RateClasses.EmptySelect ]: !pmi || !option?.includes(props.option)
  }, className);


  return <RateSelect className={classes} style={{ height: 40, paddingLeft: 16, paddingRight: 16 }} {...p}>
    <p className={RateClasses.RateSelectTitle}>
      PMI
    </p>
    {openModal && <PmiDialog open={true} onClose={() => setOpenModal(false)}/>}
    <Box
      container
      className={classNames({
        [ RatePartnerClasses.RatePartnerError ]: !!error || !!(!pmi && errors?.length),
        [ RatePartnerClasses.RatePartnerLoading ]: !!loading
      })}
      gap={(loading || error) ? "XXS" : "XS"}
      alignItems={"center"}
      >
      {
        loading
          ?
          <>
            <CircularLoader/>
            <p>Loading...</p>
          </>
          :
          error ?
            <>
              <p>
                {messages.errorPMI}
              </p>
              {
                refetch &&
                <Button variant={ButtonVariants.Ghost} onClick={() => refetch()}>
                  RETRY
                </Button>
              }
            </>
            : (!pmi && errors?.length) ?
              <>
                <p>
                  {messages.errorPMI}
                </p>
                {
                  refetch &&
                  <Button variant={ButtonVariants.Ghost} onClick={() => setOpenModal(true)}>
                    SEE RESULTS
                  </Button>
                }
              </> :
              <div className={RatePartnerClasses.RatePartnerButton} onClick={() => setOpenModal(true)}>
                {partnerImg}
              </div>
      }
    </Box>
    <p className={RateClasses.RateSelectSum}>
      {`$${format(!option?.includes(props.option) ? 0 : pmi?.premium || 0, 2)}`}
    </p>
  </RateSelect>;
});

export interface RatePartnerProps extends BoxComponentProps {
  loading?: boolean;
}
export const RatePartner: FC<RatePartnerProps> = React.memo(function RatePartner(props) {
  const { loading, ...p } = props;
  return <Box container
              direction={"column"}
              justify={loading ? "center" : "start"}
              alignItems={loading ? "center" : "stretch"}
              className={classNames(RatePartnerClasses.RatePartner)}
              {...p}>
    {
      props.children
    }
  </Box>;
});

export const PmiProviderPreview: FC<BoxComponentProps> = React.memo(function PmiProviderPreview() {
  const {
    pmi,
    error: pmiError,
    pmiResult,
    errors,
    loading: pmiLoading
  } = usePmi();
  const [openModal, setOpenModal] = useState(false);
  const warning = useMemo(() => {
    return Object.keys(pmiResult).some(key => {
      const index = pmiResult[ key ].findIndex((provider) => provider.errorMessage);
      return index > -1;
    });
  }, [pmiResult]);
  const message = useMemo(() => {
    if (pmiError || (errors?.length && !pmi)) {
      return messages.errorPMI;
    }
    if (warning) {
      return messages.warning;
    }
    return messages.initial;
  }, [pmiError, warning, errors]);

  const allFailed = useMemo(() => {
    return Object.keys(pmiResult).every(key => pmiResult[ key ].every((provider) => provider.errorMessage));
  },[pmiResult])

  return <>
    {pmiLoading ? <CircularLoader/> : <RatePartnerPreview
      variant={(warning && !allFailed) ? RatePartnerVariant.Warning : (pmiError || allFailed || (errors?.length && !pmi)) && RatePartnerVariant.Error}
      text={message}>
      {
        (pmi && !pmiError && !allFailed) ?
          <Box className={RatePartnerClasses.RatePartnerLogoSmall} onClick={() => setOpenModal(true)}
               data-hover-text="Change">
            {
                pmi.providerId &&
                <img width={56} height={24} alt={"PMI"}
                     src={`${window.__CONFIG__.assets}/pmi_logos/${pmi.providerId}_second.png`}/>
            }
          </Box>
          :
          (pmiError || errors?.length || allFailed) &&
          <Box container alignItems={"center"} justify={"center"} className={RatePartnerClasses.RatePartnerFail}>
            Failed
          </Box>
      }
    </RatePartnerPreview>}
  </>;
});
export const TitleProviderPreview: FC<BoxComponentProps> = React.memo(function TitleProviderPreview() {
  const { $object: lead } = useSource();
  const [isInitialFee, setIsInitialFee] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { titleFeeResult } = useContext(RatesContext);
  const { selectedTitleFee } = useContext(RateContext);
  const warning = useMemo(() => {
    return Object.keys(titleFeeResult.result).some(key => {
      return titleFeeResult.result[ key ].errorMessage;
    });
  }, [titleFeeResult.result]);
  const message = useMemo(() => {
    if (titleFeeResult.error) {
      return messages.errorTitle;
    }

    if (warning) {
      return messages.warning;
    }

    if (isInitialFee) {
      return messages.initial;
    }

    return messages.selected;
  }, [titleFeeResult.error, warning, isInitialFee]);
  const onClose = () => {
    setModalOpen(false);
    if (isInitialFee) {
      setIsInitialFee(false);
    }
  };

  return <>
    {modalOpen && <TitleFeeDialog open={true} onClose={onClose}/>}
    {!titleFeeResult.loading && <RatePartnerPreview
      variant={warning ? RatePartnerVariant.Warning : titleFeeResult.error && RatePartnerVariant.Error}
      text={message}>

      {
        selectedTitleFee ?
          <Box className={RatePartnerClasses.RatePartnerLogoSmall} onClick={() => setModalOpen(true)}
               data-hover-text="Change">
            <img width={56} height={24} alt={"Title fee"}
                 src={`${window.__CONFIG__.assets}/titleFee_logos/${selectedTitleFee.providerId}_second.png`}/>
          </Box>
          :
          titleFeeResult.error &&
          <Box container alignItems={"center"} justify={"center"} className={RatePartnerClasses.RatePartnerFail}>
            Failed
          </Box>
      }
    </RatePartnerPreview>}
  </>;
});

export interface RatePartnerPreviewProps extends BoxComponentProps {
  variant?: RatePartnerVariant;
  text: string;
}
export const RatePartnerPreview: FC<RatePartnerPreviewProps> = React.memo(function RatePartnerPreview(props) {
  const { variant, children, text, ...p } = props;
  const classes = classNames(RatePartnerClasses.RatePartnerPreview, {
    [ RatePartnerClasses.RatePartnerPreviewWarning ]: variant == RatePartnerVariant.Warning,
    [ RatePartnerClasses.RatePartnerPreviewError ]: variant == RatePartnerVariant.Error
  });
  return <Box container
              className={classes}
              {...p}>
    <Box container justify={"center"} alignItems={"center"} style={{ width: 24 }}>
      <Tooltip alignment={Alignment.Bottom} title={text} style={{ textAlign: "center" }}>
        <FontIcon
          type={(variant == RatePartnerVariant.Warning || variant == RatePartnerVariant.Error) ? "warning" : "info"}/>
      </Tooltip>
    </Box>
    {children}
  </Box>;
});

interface RatePartnerRowProps extends BoxComponentProps {
  title: string;
  sum: string;
  providerId?: string;
  logoPath?: string;
  loading?: boolean;
}
export const RatePartnerRow: FC<RatePartnerRowProps> = React.memo(function RatePartnerRow(props) {
  const { title, sum, providerId, loading, logoPath, children, ...p } = props;
  return <Box
    container
    flex={"0 0 30px"}
    alignItems={"center"}
    justify={loading ? "center" : "space-between"}
    style={{ padding: "4px 0" }}
    {...p}
  >
    {
      loading
        ?
        <CircularLoader/>
        :
        <>
          <p className={RatePartnerClasses.RatePartnerTitle}>
            {title}
          </p>
          {children ? <Box flex={1} container justify={"center"} gap={"XXXS"} wrap="wrap">{children}</Box> : null}
          {
            sum &&
            <p className={RatePartnerClasses.RatePartnerSum}>
              {sum}
            </p>
          }
        </>
    }
  </Box>;
});
export const RatePartnerSummaryRow: FC<RatePartnerRowProps> = React.memo(function RatePartnerSummaryRow(props) {
  const { title, sum, providerId, loading, logoPath, ...p } = props;

  const isEmpty = useMemo(() => {
    return sum === undefined || sum === null;
  }, [sum]);

  return <Box
    container
    className={classNames({
      [ RatePartnerClasses.EmptyPartner ]: isEmpty
    })}
    alignItems={"end"}
    justify={loading ? "center" : "space-between"}
    style={{ padding: "4px 0", justifySelf: "end" }}
    flex={"1 0 35px"}
    {...p}
  >
    {
      loading
        ?
        <CircularLoader/>
        :
        <>
          <p className={RatePartnerClasses.RatePartnerTitleTotal}>
            {title}
          </p>
          {
            sum &&
            <p className={RatePartnerClasses.RatePartnerSumTotal}>
              {sum}
            </p>
          }
        </>
    }
  </Box>;
});
