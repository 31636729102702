import React                      from "react";
import { FC }                     from "react";
import { Box }                    from "@relcu/ui";
import { JsonViewProps }          from "@relcu/ui";
import { useSource }              from "@relcu/ui";
import { TypographyColor }        from "@relcu/ui";
import { TypographyWeight }       from "@relcu/ui";
import { TypographySize }         from "@relcu/ui";
import { Typography }             from "@relcu/ui";
import { usePreviewLoanProposal } from "../../../Modal/ProposalPdfModal/usePreviewLoanProposal";
import { LoanCriteriaForm }       from "./LoanCriteria";
import { PendingOffers }          from "./PendingOffers";
import { PricingViewClasses }     from "./PricingViewClasses";
import { Results }                from "./Results";
import { usePricingView }         from "./usePricingView";
import "./pricing-view.css";

export const PricingView: FC<JsonViewProps> = React.memo(function PricingView(props) {
  const api = usePricingView();
  const { $object: { objectId } } = useSource();
  const { preview, modalContext } = usePreviewLoanProposal(objectId);

  return <Box container direction={"column"} gap={"XS"} style={{ overflow: "hidden" }}>
    <Box container className={PricingViewClasses.PricingView} gap={"XXXS"} flex={1}>
      <LoanCriteriaForm  initialValues={api.initialValues} onCriteriaSubmit={api.onCriteriaSubmit}/>
      <Box container direction="column" gap={"XXXS"} flex={"auto"}>
        {
          !!api.offersCount &&
          <PendingOffers
            onPreviewPdf={preview}
            headers={props.headers}
            selectedFields={props.fields}
            offers={api.offers}
            onRemove={api.removeOffer}
            count={api.offersCount}
            loading={api.offersLoading}/>
        }
        <Box className={"header"} style={{background: "#fff", padding: "8px", borderRadius: 4}} container alignItems={"center"} gap={"XS"}>
          <Typography size={TypographySize.SubtitleSmall} weight={TypographyWeight.Medium} color={TypographyColor.ExtraDark}>
            Available offers
          </Typography>
        </Box>
        <Results
          criteriaData={api.criteriaDataVar}
          titleFeeResult={{
            result: api.titleFees.result,
            error: api.titleFees.error,
            createTitleFeeInput: api.createTitleFeeInput,
            loading: api.titleFeesLoading,
            refetch: api.titleFeesRefetch,
            update: api.updateTitleFees
          }}
          offers={{
            count: api.offersCount,
            loading: api.offersLoading
          }}
          stateBasedFee={api.stateBasedFee}
          appraisalFees={api.appraisalFees}
          rates={{ loading: api.mortechLoading, data: api.mortechData }}
          pmi={{
            isPmiEligible: api.isPmiEligible,
            result: api.pmiData,
            errors: api.pmiErrors,
            loading: api.pmiLoading,
            refetch: api.refetch,
            createPmiInput: api.createPmiInput,
            error: api.error
          }}
          />
      </Box>
    </Box>
    {modalContext}
  </Box>;
});
export default PricingView;
