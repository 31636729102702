import { useReactiveVar } from "@apollo/client";
import { makeVar }        from "@apollo/client";
import { usePrevious }    from "@relcu/ui";
import { useConstant }    from "@relcu/ui";
import { SyntheticEvent } from "react";
import { useContext }     from "react";
import { useRef }         from "react";
import { useState }       from "react";
import { useMemo }        from "react";
import { TitleFee }       from "../__types__/TitleFee";
import { PersistContext } from "./PersistProvider";
import { ResultsProps }   from "./Results";

const TypeMap = {
  "single_family_detached": "singleFamily",
  "pud": "pud",
  "townhome": "townhome",
  "detached_condo": "condo",
  "2_unit": "multiFamily",
  "3_unit": "multiFamily",
  "4_unit": "multiFamily",
  "warrantable_condo_lt_5_stories": "condo",
  "warrantable_condo_5_to_8_stories": "condo",
  "warrantable_condo_gt_8_stories": "condo",
  "non_warrantable_condo_lt_5_stories": "condo",
  "non_warrantable_condo_5_to_8_stories": "condo",
  "non_warrantable_condo_gt_8_stories": "condo",
  "condotel_condo_lt_5_stories": "condo",
  "condotel_condo_5_to_8_stories": "condo",
  "condotel_condo_gt_8_stories": "condo",
  "mobile": "mobile",
  "manufactured": "manufactured",
  "co_op": "coOp"
};

export function useRates(props: ResultsProps) {
  const { pmi, rates: { data = [] }, stateBasedFee, appraisalFees, titleFeeResult } = props;
  const selectedTitleFeeIdVar = useConstant(() => makeVar(undefined));
  const customTitleFeeVar = useConstant(() => makeVar<TitleFee>(null));
  const selectedPmiVar = useConstant(() => makeVar(null));
  const { data: { waive }, leadDataVar } = useContext(PersistContext);
  const lead = useReactiveVar(leadDataVar);
  const prevRates = usePrevious(data);
  const sameRates = prevRates === data;
  const pmiResult = useMemo(() => {
    return {
      ...pmi,
      result: Object.fromEntries(Object.entries<any>(pmi?.result || {}).map(([key, r]) => {
        return [key, [...r].sort((a, b) => a.premium - b.premium)];
      }))
    };
  }, [pmi]);
  const rates = useMemo(() => {
    let rates = [];
    let index = 0;
    for (const product of data) {
      for (const result of product.results) {
        let feeAmount = 0;
        for (let fee of result.quote.quoteDetails.fees) {
          feeAmount += fee.feeAmount;
        }
        let rate = {
          index,
          productName: product.productName,
          productTerm: result.quote.productTerm,
          productDesc: result.quote.productDesc,
          pricingStatus: result.quote.pricingStatus,
          rate: result.quote.quoteDetails.rate,
          apr: result.quote.quoteDetails.apr,
          price: (100 - result.quote.quoteDetails.price),
          points: result.quote.quoteDetails.price,
          piti: result.quote.quoteDetails.piti,
          srp: result.quote.quoteDetails.srp,
          adjustments: result.quote.quoteDetails.adjustments,
          fees: result.quote.quoteDetails.fees,
          loanAmount: result.quote.quoteDetails.loanAmount,
          feeAmount: feeAmount,
          investor: result.quote.vendorName.split("(")[ 0 ],
          lastUpdate: result.quote.lastUpdate,
          monthlyPremium: result.quote.quoteDetails.monthlyPremium
        };
        rate[ "feesCalculated" ] = feeAmount + (rate.points < 0 ? 0 : ((rate.points * rate.loanAmount) / 100));
        rates.push(rate);
        index++;
      }
    }
    return rates;
  }, [data]);
  const defaultSelectedRef = useRef(null);

  const [active, setActive] = useState(() => {
    if (rates.length == 0) {
      return 0;
    }
    let sortedRates = rates.filter((r) => (r.points <= 0));
    sortedRates = sortedRates.sort((r1, r2) => {
      if (r1.rate < r2.rate) {
        return -1;
      }
      if (r2.rate < r1.rate) {
        return 1;
      }
      if (r1.price < r2.price) {
        return 1;
      }
      if (r2.price < r1.price) {
        return -1;
      }
      return 0;
    });
    return sortedRates[ 0 ]?.index ?? rates[ rates.length - 1 ]?.index;
  });
  const [sticky, setSticky] = useState(active);
  const [best, setBest] = useState(active);

  // useEffect(() => {
  //   if (sortedRates && sortedRates.monthly) {
  //     setPmi({ ...sortedRates.monthly.find(r => !r.errorMessage), option: "monthly" });
  //   } else {
  //     setPmi(null);
  //   }
  // }, [sortedRates]);

  function getAppraisalFeeValue() {
    if (!appraisalFees?.length) {
      return 0;
    } else {
      if (appraisalFees?.length == 1) {
        return appraisalFees[ 0 ][ TypeMap[ lead.property.type ] ] || 0;
      } else {
        const countyFee = appraisalFees?.find(fee => fee.county != "*");
        if (countyFee && (!!countyFee[ TypeMap[ lead.property.type ] ] || countyFee[ TypeMap[ lead.property.type ] ] == 0)) {
          return countyFee[ TypeMap[ lead.property.type ] ];
        } else {
          return appraisalFees?.find(fee => fee.county == "*")[ TypeMap[ lead.property.type ] ] || 0;
        }
      }
    }
  }

  function rateStick(e: SyntheticEvent, stick, index) {
    e.stopPropagation();
    if (!stick) {
      setSticky(index);
      setActive(index);
    } else {
      setSticky(null);
    }
  }
  return {
    titleFeeResult,
    pmiResult,
    keepDirtyOnReinitialize: sameRates,
    createTitleFeeInput: titleFeeResult.createTitleFeeInput,
    appraisalFeeValue: waive ? 0 : getAppraisalFeeValue(),
    condoCertificateFee: stateBasedFee?.condoCertFee || 0,
    defaultSelectedRef,
    best,
    setBest,
    sticky,
    setSticky,
    active,
    setActive,
    rateStick,
    rates,
    getAppraisalFeeValue,
    selected: {
      title: {
        customTitleFeeVar,
        selectedTitleFeeIdVar
      },
      pmi: {
        selectedPmiVar
      }
    }
    // get isMonthlyPmi() {
    //   return pmi?.option == "monthly";
    // },
    // get monthlyPmi() {
    //   return pmi?.option == "monthly" ? pmi : null;
    // }
  };
}
