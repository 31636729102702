import { useState }               from "react";
import { useCallback }            from "react";
import React                      from "react";
import { useContext }             from "react";
import { FC }                     from "react";
import { DateTime }               from "luxon";
import { Form, FormSpy }          from "@relcu/form";
import { classNames }             from "@relcu/ui";
import { ButtonVariants }         from "@relcu/ui";
import { Button }                 from "@relcu/ui";
import { Accordion }              from "@relcu/ui";
import { classNames as clsNames } from "@relcu/ui";
import { BoxComponentProps }      from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { defaultMutators }        from "@relcu/ui";
import { GlobalClasses }          from "@relcu/ui";
import { format }                 from "../../../../../../utils/helpers";
import { Adjustments }            from "../Adjustments";
import TotalClosingCosts          from "../ClosingCosts/TotalClosingCosts";
import { MonthlyPayment }         from "../MonthlyPayment/MonthlyPayment";
import { MonthlyPaymentSum }      from "../MonthlyPayment/MonthlyPaymentSum";
import { PersistContext }         from "../PersistProvider";
import { RatesContext }           from "../RatesProvider";
import { RefinanceClosing }       from "./CashAtClosing";
import { PurchaseClosing }        from "./CashAtClosing";
import { RateClasses }            from "./RateClasses";
import { IRate }                  from "./RateProvider";
import { RateContext }            from "./RateProvider";
import "./rate.css";
import { useRate }                from "./useRate";

export interface RateProps extends BoxComponentProps {
  active?: boolean
  sticky?: boolean
  best?: boolean
  index: number,
  rate: IRate,
}

export const Rate: FC<RateProps> = React.memo(React.forwardRef(function Rate(props, ref) {
  const { index, rate, className, children, active, sticky, best, ...p } = props;
  const { rateStick } = useContext(RatesContext);
  const providerValue = useRate(rate, active, sticky);
  const { handleSubmit, initialValues, productName } = providerValue;
  return <Form onSubmit={handleSubmit} mutators={defaultMutators} initialValues={initialValues}>
    {({ form, handleSubmit }) => {
      return <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
        <RateContext.Provider value={providerValue}>
          <RateItem
            active={active}
            className={classNames}
            best={best}
            index={index}
            sticky={sticky}
            rateStick={rateStick}
            inputRef={ref}
            {...p}
          />
        </RateContext.Provider>
      </form>;
    }}
  </Form>;
}));
function RateItem({ index, rateStick, active, best, className, sticky, inputRef, ...p }) {
  const classNames = clsNames(RateClasses.Rate, {
    [ RateClasses.Active ]: active,
    [ RateClasses.Best ]: best
  }, className);
  return <Accordion
    collapse={sticky}
    onToggle={(open, e) => {
      rateStick(e, sticky, index);
    }}
    container
    direction={"column"}
    className={classNames}
    openClass={RateClasses.Open}
    InputProps={{ ref: inputRef }}
    {...p}
    header={(open) => <RateHeader/>}
  >
    <RateBody/>
  </Accordion>;
}

function RateHeader({ ...props }) {
  const { productName, investor, rate, price, apr, lastUpdate } = useContext(RateContext);
  let lastUpdateFmt = DateTime.fromISO(lastUpdate).toFormat("hh:mm:ss a");
  return <div className={RateClasses.RateHeader} {...props}>
    <RateHeaderCell title={productName} value={investor}/>
    <RateHeaderCell size={"60px"} flexShrink={1} title="Rate" value={`${format(rate, 3)}%`}/>
    <RateHeaderCell size={"60px"} flexShrink={1} title="APR" value={`${format(apr, 3)}%`}/>
    <RateHeaderCell size={"60px"} flexShrink={1} title="Price" value={`${format(price, 3)}`}/>
    <PurchaseClosing>
      {(value) => {
        return <RateHeaderCell size={"100px"} title={"Cash to close"} value={`$${format(value, 2)}`}
                               icon={value > 0 ? "⊖" : "⊕"}/>;
      }}
    </PurchaseClosing>
    <RefinanceClosing>
      {(value) => {
        return <RateHeaderCell size={"100px"} title={"Cash to close"}
                               value={`$${format(Math.abs(value as number), 2)}`} icon={value > 0 ? "⊖" : "⊕"}/>;
      }}
    </RefinanceClosing>
    <MonthlyPaymentSum>
      {(value) => <RateHeaderCell size={"100px"} title={"Monthly Payment"} value={`$${format(value, 2)}`}/>}
    </MonthlyPaymentSum>
    <RateHeaderCell size={"90px"} revers title="Updated" value={lastUpdateFmt}/>
  </div>;
}
export const RateBody = React.memo(function RateBody() {
  const { changeData, offers } = useContext(PersistContext);
  const {
    titleFeeResult,
    rateStick,
    pmiResult: {
      loading: pmiLoading,
      error: pmiError,
      errors: pmiErrors,
      isPmiEligible
    }

  } = useContext(RatesContext);
  const { adjustments, sticky, index } = useContext(RateContext);
  const [position, setPosition] = useState<"start" | "end">("start");
  const handleScroll = useCallback((e) => {
    if (e.target.scrollTop == 0) {
      setPosition("start");
    } else if (e.target.scrollTop == e.target.scrollHeight - e.target.clientHeight) {
      setPosition("end");
    } else {
      setPosition(null);
    }
  }, []);

  return <div className={RateClasses.RateBody}>
    <Box className={
      classNames({
        [ RateClasses.ScrollContainer ]: sticky,
        [ RateClasses.ScrollContainerTop ]: position == "start",
        [ RateClasses.ScrollContainerBottom ]: position == "end"
      })
    }>
      <Box container direction={"column"} className={RateClasses.Container}
           onScroll={handleScroll}>
        <FormSpy subscription={{ values: true, valid: true }}
                 onChange={({ values, valid }) => valid && changeData(values)}/>

        <RateSection>
          <MonthlyPayment/>
        </RateSection>
        <RateSection>
          <TotalClosingCosts/>
        </RateSection>
        <RateSection>
          <Box container justify={"space-between"} className={RateClasses.RateSectionHeader}>
            <h2 className={RateClasses.SectionTitle}>Adjustments</h2>
          </Box>
          <Adjustments>
            {
              adjustments.adjustmentDetails.map((adjustment, k) => (
                <tr key={`adjustments-${k}`}>
                  <td align="left">{adjustment.desc}</td>
                  <td align="right">{format(adjustment.rateAdj, 3)}%</td>
                  <td align="right">{format(adjustment.priceAdj, 3)}%</td>
                  <td align="right">{format(adjustment.marginAdj, 3)}%</td>
                </tr>
              ))
            }
          </Adjustments>
        </RateSection>
      </Box>
    </Box>
    <Box container justify={"end"} className={RateClasses.RateActions}>
      <Button
        onClick={(e) => rateStick(e, true, index)}
        variant={ButtonVariants.Ghost}
      >
        CLOSE
      </Button>
      <Button
        type="submit"
        disabled={(isPmiEligible && pmiLoading) || titleFeeResult.loading || offers.loading || offers.count >= 3}
      >
        SELECT OFFER
      </Button>
    </Box>

  </div>;
});

export interface RateItemProps extends BoxComponentProps {
  title: string,
  value: string,
  icon?: string,
  revers?: boolean
  size?
}
export const RateHeaderCell: FC<RateItemProps> = React.memo(function RateItem(props) {
  const { title, value, revers, className, size, flexBasis, flexShrink, icon, ...p } = props;
  p.style = { minWidth: size, flex: !size ? "auto" : undefined };
  const classNames = clsNames(RateClasses.RateHeaderCell, { [ RateClasses.RateItemReverse ]: revers }, className);
  return <div className={classNames} {...p}>
    <p className={RateClasses.RateTitle}>{title}</p>
    <Box container gap={"XXXS"} data-support-text={icon} className={RateClasses.RateValueContainer}
         alignItems={"center"}>
      <p className={clsNames(RateClasses.RateValue, GlobalClasses.EllipsisFlex)}>{value}</p>
    </Box>
  </div>;
});
export const RateSection: FC<BoxComponentProps> = React.memo(function RateSection(props) {
  const { className, ...p } = props;
  const classNames = clsNames(RateClasses.RateSection, className);
  return <Box container direction={"column"} className={classNames} {...p}/>;
});


