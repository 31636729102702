import React                                                      from "react";
import { FC }                                                     from "react";
import { useCallback }                                            from "react";
import { useState }                                               from "react";
import { useMemo }                                                from "react";
import { useRef }                                                 from "react";
import { applyBoxItemStyles, PointerFieldProps, useConsumeField } from "@relcu/ui";
import { MenuItem }                                               from "@relcu/ui";
import { AvatarSizes }                                            from "@relcu/ui";
import { Link }                                                   from "@relcu/ui";
import { Avatar }                                                 from "@relcu/ui";
import { Box }                                                    from "@relcu/ui";
import { SearchableSelect }                                       from "@relcu/ui";
import { InputState }                                             from "@relcu/ui";
import { parsePhoneNumber }                                       from "@relcu/ui";
import { getSelectionSet }                                        from "../../../../../utils/graphQlUtils";
import { formatPhoneNumber }                                      from "../../../../../utils/helpers";
import { getObjectPath }                                          from "../../../../../utils/layoutUtils";
import { useJqlQuery }                                            from "../../../Jql";
import { ContactDetailsClasses }                                  from "./ContactDetailsClasses";

export const ContactPointerEditField: FC<any> = React.memo(function ContactPointerEditField(props) {
  const selectRef: any = useRef();
  const [q, setQ] = useState("");
  const { input, meta: { touched, error, submitError } } = useConsumeField<PointerFieldProps>();
  const hasError = (touched && !!error) || !!submitError;
  const selectionSet = useMemo(() => getSelectionSet([
    "types",
    {
      "phones": ["number", "default", "type"]
    },
    {
      "emails": ["address", "type"]
    },
    "firstName",
    "lastName",
    "middleName"
  ]), []);
  const matchers = useMemo(() => {
    let safe = q?.trim() || "";

    if (q && (q.includes("+") || q.includes("(") || q.includes(")"))) {
      safe = (q).replace(/[+()]/g, "").trim();
    }
    let phone = safe;
    if (safe && (safe.includes("(") || safe.includes(" ") || safe.includes("-") || safe.includes(")"))) {
      phone = parsePhoneNumber(safe);
    }
    if (phone && phone.includes(".")) {
      phone = (phone).replace(/[.]/g, "");
    }
    return { safe, phone };
  }, [q]);
  const query = useMemo(() => ({
    operation: "contacts",
    variables: {
      first: 10,
      where: {
        type: `ContactWhereInput`,
        value: {
          OR: [
            {
              objectName: {
                matchesRegex: `(?i)${(matchers.safe || "")}`,
                options: "i"
              }
            },
            {
              emails: {
                have: {
                  address: {
                    matchesRegex: `.*${matchers.safe}.*`,
                    options: "i"
                  }
                }
              }
            },
            {
              phones: {
                have: {
                  number: {
                    matchesRegex: `.*${matchers.phone}.*`
                  }
                }
              }
            }
          ]
        }
      }
    },
    fields: [
      {
        edges: [
          {
            node: selectionSet
          }
        ]
      }
    ]
  }), [q]);
  const { data = {}, loading } = useJqlQuery(query, {
    operationName: `ContactSelector`
  });
  const options = useMemo(() => {
      return (
        Object(data[ "contacts" ])[ "edges" ] || []
      ).map(({ node }) => node);
    },
    [data]
  );
  const handleSelect = useCallback((data) => {
    input.onChange(data);
    selectRef.current.handleClose();
  }, [input, selectRef.current]);
  const { style } = applyBoxItemStyles(input);

  const renderOptions = (option) => {
    let defaultPhoneNumber = option.phones.find(el => el.default)?.number;
    if (!defaultPhoneNumber) {
      defaultPhoneNumber = option.phones[ 0 ]?.number;
    }
    defaultPhoneNumber = defaultPhoneNumber ? `+1 ${formatPhoneNumber(defaultPhoneNumber)}` : "No phone number";
    let emailAddress = option.emails[ 0 ]?.address || "No email address";
    return (
      <MenuItem container thumbnail={<Avatar icon={option.objectIcon} size={AvatarSizes.Small}
                                             text={option.objectName}/>}>
        <Box className={ContactDetailsClasses.ContactContainer}>
          {option.objectName}
          <Box className={ContactDetailsClasses.ContactInfo}>
            {emailAddress}
          </Box>
          <Box className={ContactDetailsClasses.ContactInfo}>
            {defaultPhoneNumber}
          </Box>
        </Box>
      </MenuItem>
      /*todo do not add event listeners here onSelect will came from SearchableSelect, need to check with real data if need remove React.CloneElement from Searchable Menu add select items from here*/
    );
  };

  return (
    <SearchableSelect
      {...input}
      ref={selectRef}
      options={options}
      value={input.value.id ? input.value : null}
      searchPlaceholder={"by email, phone or name"}
      onType={value => setQ(value)}
      searchText={q}
      loading={loading}
      optionKey={"id"}
      onSelect={handleSelect}
      onCreateNew={input?.onCreateNew}
      renderOption={(option) => renderOptions(option)}
      renderSelect={(selected) => (
        <Box container /*className={PointerDetailsClasses.PointerSelect}*/ gap={"XXS"} alignItems={"center"}>
          {
            selected.objectId &&
            <Avatar icon={selected.objectIcon} size={AvatarSizes.Small} text={selected.objectName}/>
          }
          {
            !input.readOnly
              ?
              selected.objectName
              :
              <Link
                to={getObjectPath(selected)}>
                {selected.objectName}
              </Link>
          }
        </Box>
      )}
      name={props.name}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error.id || error || submitError) : undefined}
      menuProps={{ style: { minWidth: "275px" } }}
      style={style}
    />
  );
});
export default ContactPointerEditField;
