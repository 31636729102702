import React                               from "react";
import { FC }                              from "react";
import { useContext }                      from "react";
import { useMemo }                         from "react";
import { useCallback }                     from "react";
import { Dispatch }                        from "react";
import { SetStateAction }                  from "react";
import { useQuery }                        from "@apollo/client";
import { useReactiveVar }                  from "@apollo/client";
import { useMutation }                     from "@apollo/client";
import { gql }                             from "@apollo/client";
import { useParams }                       from "@relcu/react-router";
import { useLocation }                     from "@relcu/react-router";
import { Location }                        from "@relcu/react-router";
import { useAlert }                        from "@relcu/ui";
import { EmptyList }                       from "@relcu/ui";
import { ChipsColors }                     from "@relcu/ui";
import { Chips }                           from "@relcu/ui";
import { CircularLoader }                  from "@relcu/ui";
import { Box }                             from "@relcu/ui";
import { useSource }                       from "@relcu/ui";
import { ClientContext }                   from "../../Client";
import { DOCUMENT }                        from "../../graph/operations.graphql";
import { FILE_FRAGMENT }                   from "../../graph/operations.graphql";
import { MESSAGE_PARTICIPANT_FRAGMENT }    from "../../graph/operations.graphql";
import { MEMBER_OWN_FIELDS_FRAGMENT }      from "../../graph/operations.graphql";
import { messageDraftsVar }                from "../../reactiveVars";
import { toNodeId }                        from "../../utils/helpers";
import { createPhoneNumbers }              from "../../utils/helpers";
import { divideDataByDate }                from "../../utils/helpers";
import { getContactName }                  from "../../utils/helpers";
import { GetLoanEstimateSentVariables }    from "../__types__/GetLoanEstimateSent";
import { GetLoanEstimateSent }             from "../__types__/GetLoanEstimateSent";
import { GetLoanProposalSentVariables }    from "../__types__/GetLoanProposalSent";
import { GetLoanProposalSent }             from "../__types__/GetLoanProposalSent";
import { MarkMessagesAsReadVariables }     from "../__types__/MarkMessagesAsRead";
import { MarkMessagesAsRead }              from "../__types__/MarkMessagesAsRead";
import { SendPhoneMessageVariables }       from "../__types__/SendPhoneMessage";
import { SendPhoneMessage }                from "../__types__/SendPhoneMessage";
import { UpdateLoanEstimateSentVariables } from "../__types__/UpdateLoanEstimateSent";
import { UpdateLoanEstimateSent }          from "../__types__/UpdateLoanEstimateSent";
import { UpdateLoanProposalVariables }     from "../__types__/UpdateLoanProposal";
import { UpdateLoanProposal }              from "../__types__/UpdateLoanProposal";
import { useMessageTemplates }             from "../Layout/View/MessageView/useMessageTemplates";
import { ActionBar }                       from "../Message/ActionBar";
import { Content }                         from "../Message/Content";
import { Msg }                             from "../Message/Msg";
import { GET_LOAN_ESTIMATE_SENT }          from "../operations.graphql";
import { GET_LOAN_PROPOSAL_SENT }          from "../operations.graphql";
import { UPDATE_LOAN_ESTIMATE_SENT }       from "../operations.graphql";
import { UPDATE_LOAN_PROPOSAL }            from "../operations.graphql";
import { MARK_MESSAGES_AS_READ }           from "../operations.graphql";
import { SEND_MESSAGE }                    from "../operations.graphql";
import { RelayMention }                    from "../Relay";
import { RelayMentionProvider }            from "../Relay";
import { RelayQuery }                      from "../Relay";
import { useViewerPhoneLines }             from "../useViewerPhoneLines";
import { GetMessages }                     from "./__types__/GetMessages";
import { UpdatePhoneMessageLeadVariables } from "./__types__/UpdatePhoneMessageLead";
import { UpdatePhoneMessageLead }          from "./__types__/UpdatePhoneMessageLead";
import "./phone-message-thread.css";

export interface PhoneMessageThreadProps {
  duplicateOf?: boolean;
  canWrite?: boolean;
  queryVariables: any,
  selectedMsgs?: string[],
  setSelectedMsgs?: Dispatch<SetStateAction<string[]>>,
  allowBulkConnection?: boolean,
  subscriptionVariables: any,
  scopeId?: string,
  contact?: any,
  nearBy?: string
  to?: {
    value: string,
    label: string,
    isPrimary: boolean,
    smsOptOut: boolean,
    callOptOut: boolean,
    disabled: boolean
  }[]
}

export const PhoneMessageThread: FC<PhoneMessageThreadProps> = React.memo(function PhoneMessageThread(props) {
  const {
    duplicateOf,
    canWrite,
    allowBulkConnection,
    queryVariables,
    subscriptionVariables,
    selectedMsgs,
    setSelectedMsgs,
    to: toNumbers,
    contact,
    nearBy = false
  } = props;
  const { $viewer, $object } = useSource();
  const { state }: Location<Record<string, any>> = useLocation();
  const { id: userId } = $viewer;
  const { tw } = useContext(ClientContext);
  const params = useParams();
  const objectId = nearBy ?? params[ "*" ];
  const draft = useReactiveVar(messageDraftsVar);
  const changeSelection = useCallback((objectId) => {
    setSelectedMsgs(selected => {
      if (selected.includes(objectId)) {
        return selected.filter(id => id !== objectId);
      }
      return [...selected, objectId];
    });
  }, [setSelectedMsgs]);
  const [sendMessage] = useMutation<SendPhoneMessage, SendPhoneMessageVariables>(SEND_MESSAGE);
  const [update] = useMutation<UpdatePhoneMessageLead, UpdatePhoneMessageLeadVariables>(ASSIGN_LEAD_TO_MESSAGE);
  const [markAsRead] = useMutation<MarkMessagesAsRead, MarkMessagesAsReadVariables>(MARK_MESSAGES_AS_READ);
  const [updateProposal] = useMutation<UpdateLoanProposal, UpdateLoanProposalVariables>(UPDATE_LOAN_PROPOSAL);
  const [updateLoanEstimate] = useMutation<UpdateLoanEstimateSent, UpdateLoanEstimateSentVariables>(UPDATE_LOAN_ESTIMATE_SENT);
  const { fromNumbers: from } = useViewerPhoneLines();
  const to = useMemo(() => toNumbers ?? createPhoneNumbers($object, "smsOptOut"), [$object, toNumbers]);
  const { templates } = useMessageTemplates();
  const { error } = useAlert();
  const proposalId = draft[ $object.objectId ]?.proposalId;
  const loanEstimateId = draft[ $object.objectId ]?.loanEstimateId;
  const proposalQuery = useQuery<GetLoanProposalSent, GetLoanProposalSentVariables>(GET_LOAN_PROPOSAL_SENT, {
    skip: !proposalId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      id: proposalId
    }
  });

  const loanEstimateQuery = useQuery<GetLoanEstimateSent, GetLoanEstimateSentVariables>(GET_LOAN_ESTIMATE_SENT, {
    skip: !loanEstimateId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      id: loanEstimateId
    }
  });
  const numbers = { preferred: { to: state?.to, from: state?.from }, to, from };
  let receiver = useMemo(() => {
    const result = [];
    if ($object.__typename == "Lead") {
      ($object.members || []).map((member) => {
        member.contact?.phones?.map((phone) => {
          if (!result.find((n) => n.number == phone.number)) {
            result.push({
              firstName: member.contact.firstName,
              lastName: member.contact.lastName,
              number: phone.number
            });
          }
        });
      });
    } else if (contact) {
      (contact.phones || []).map((phone) => {
        if (!result.find((n) => n.number == phone.number)) {
          result.push(
            {
              firstName: contact.firstName,
              lastName: contact.lastName,
              number: phone.number
            });
        }
      });
    } else {
      ($object.phones || []).map((phone) => {
        if (!result.find((n) => n.number == phone.number)) {
          result.push(
            {
              firstName: $object.firstName,
              lastName: $object.lastName,
              number: phone.number
            });
        }
      });
    }
    return result;
  }, [$object, contact]);
  const send = async (variables: SendPhoneMessageVariables) => {
    try {
      const attachment = draft[ $object.objectId ]?.attachment;
      const proposalAttached = variables.attachments.findIndex(att => attachment?.findIndex(d => d.attachment.url === att.url) > -1);
      if ($object.__typename == "Lead") {
        variables.scopeId = toNodeId({ className: "Lead", objectId: $object.objectId });
        if (!await tw.checkTimezone($object.objectId)) {
          return;
        }
      }
      if (props.scopeId) {
        variables.scopeId = props.scopeId;
      }
      if (loanEstimateId && proposalAttached > -1) {
        variables.loanEstimateId = loanEstimateId;
      }
      return await sendMessage({
        variables
      }).then(() => {
        if (proposalId && proposalAttached > -1) {
          const { data: { loanProposal: { sent } } } = proposalQuery;
          updateProposal({
            variables: {
              id: proposalId,
              sent: sent ? sent + 1 : 1
            }
          });
        }
        if (loanEstimateId && proposalAttached > -1) {
          const { data: { loanEstimate: { sent } } } = loanEstimateQuery;
          updateLoanEstimate({
            variables: {
              id: loanEstimateId,
              sent: sent ? sent + 1 : 1
            }
          });
        }
        return messageDraftsVar({});
      });
    } catch (e) {
      console.error(e);
      error("Failed to send the message.");
    }
  };
  return (
    <RelayQuery<GetMessages>
      className={"PhoneMessage"}
      reverse={true}
      rowHeight={71}
      from={objectId}
      query={{
        document: GET_MESSAGES,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: queryVariables
      }}
      subscription={{
        document: SUBSCRIBE_MESSAGES,
        variables: subscriptionVariables
      }}
      render={(renderProps) => {
        const {
          scrollContainerRef,
          beforeLoaderRef,
          afterLoaderRef,
          loading,
          data: { phoneMessages: { pageInfo, edges = [] } } = {},
          register
        } = renderProps;
        const dividedData = divideDataByDate(edges);
        const isEmpty = edges.length == 0;
        return (
          <Box container flex={1} className={"phone-message-thread"} direction={"column"}>
            <div className={"phone-message-thread_mention-container"}>
              <Content ref={scrollContainerRef} flexGrow={1} style={{ height: "100%" }}
                       justify={isEmpty ? "center" : null}>
                <Box flex={"1 1 auto"} justify={"center"}>{
                  pageInfo?.hasPreviousPage &&
                  <CircularLoader alignSelf={"center"} justify={"center"} ref={beforeLoaderRef}/>
                }
                </Box>
                {
                  !isEmpty ?
                    dividedData?.map((edge, index) => {
                      return (
                        Date.parse(edge) ?
                          <Chips disabled color={ChipsColors.Grey} key={index}
                                 label={edge} alignSelf={"center"}/>
                          :
                          (() => {
                            const { node: mNode, cursor } = edge;
                            const { unread, ...messageNode } = mNode;
                            let sender = messageNode.participants.find((p) => p.type == "sender");
                            return <Msg
                              checkboxChecked={selectedMsgs?.includes(messageNode.objectId)}
                              key={messageNode.id}
                              showCheckbox={selectedMsgs?.length}
                              onCheck={allowBulkConnection ? () => changeSelection(messageNode.objectId) : null}
                              onConnect={(item) => update({
                                variables: {
                                  id: messageNode.id,
                                  fields: {
                                    scope: {
                                      link: item.id
                                    }
                                  }
                                }
                              })}
                              onRemove={() => update({
                                variables: {
                                  id: messageNode.id,
                                  fields: {
                                    scope: {
                                      link: null
                                    }
                                  }
                                }
                              })}
                              onResend={() => send({
                                from: messageNode.from,
                                to: messageNode.to,
                                message: messageNode.content,
                                attachments: messageNode.attachments?.map(attachment => ({ objectId: attachment.objectId, url: attachment.url }))
                              })}
                              ref={e => register(e, messageNode.objectId)}
                              viewerId={userId}
                              {...messageNode}
                              objectName={getContactName(sender?.party, $object)}
                              objectIcon={sender?.party?.objectIcon}
                              id={sender?.party?.id}
                            />;
                          })()
                      );
                    })
                    :
                    <Box flex={"1 1 auto"} justify={"center"}>
                      {loading ? <CircularLoader/> : (
                        <EmptyList icon={"forum"} content={"When you have messages, you'll see them here."}
                                   title={"No messages"}
                                   alignSelf={"center"}/>
                      )
                      }
                    </Box>
                }
                {
                  pageInfo?.hasNextPage && <Box flex={"1 1 auto"} justify={"center"}>
                    <CircularLoader alignSelf={"center"} justify={"center"} ref={afterLoaderRef}/>
                  </Box>
                }
              </Content>
              <RelayMentionProvider<GetMessages>
                variables={{
                  order: ["createdAt_ASC"],
                  where: {
                    unread: { equalTo: true },
                    AND: [
                      {
                        participants: {
                          have: {
                            party: {
                              have: {
                                link: $viewer.id
                              }
                            }
                          }
                        }
                      }
                    ]
                  }
                }}
                predicate={({ phoneMessages: { edges = [] } }) => edges.filter(e => {
                  return e.node.unread && !!e.node.participants.find(p => p.party?.objectId === $viewer.objectId);
                }).map(e => e.node.objectId)}
                onIntersecting={(objectId) => {
                  return markAsRead({
                    variables: { ids: [toNodeId({ className: "PhoneMessage", objectId })] }
                  });
                }}
              >
                <RelayMention direction={"top"} width={150}>New message</RelayMention>
                <RelayMention direction={"bottom"} width={150}>New message</RelayMention>
              </RelayMentionProvider>
            </div>
            {
              (!duplicateOf && canWrite) &&
              <ActionBar
                key={props?.contact?.objectId}
                receiver={receiver}
                templates={templates}
                preferred={numbers.preferred}
                from={numbers.from}
                to={numbers.to}
                onSend={(input) => send({
                  from: input.from?.value || input.from,
                  to: input.to?.value || input.to,
                  message: input.content,
                  templateId: input.template || null,
                  attachments: input.attachments
                })
                }/>
            }
          </Box>
        );
      }}
    />
  );
});

PhoneMessageThread.defaultProps = {
  canWrite: true,
  allowBulkConnection: false
};

export const MESSAGE_FRAGMENT = gql`
  ${DOCUMENT}
  ${MESSAGE_PARTICIPANT_FRAGMENT}
  ${FILE_FRAGMENT}
  fragment PhoneMessage on PhoneMessage {
    id
    objectId
    objectIcon
    objectName
    from
    to
    direction
    content
    createdAt
    status
    unread
    errorCode
    bulkConversation {
      objectId
      participants {
        ...MessageParticipant
      }
    }
    attachments{
      ...File
    }
    scope {
      ... on Node {
        id
      }
      ...Document
    }
    phoneMessageConversation: conversation {
      id
      objectId
      objectName
      content
      lastUpdatedAt
    }
    participants {
      ...MessageParticipant
    }
  }
`;
export const GET_MESSAGES = gql`
  ${MESSAGE_FRAGMENT}
  query GetMessages($last:Int,$first:Int,$before:String,$after:String,$from:String, $where: PhoneMessageWhereInput!) {
    phoneMessages(
      last: $last,
      first: $first,
      before: $before,
      from: $from,
      after: $after,
      where: $where
      order: [createdAt_ASC],
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...PhoneMessage
        }
      }
    }
  }
`;
export const SUBSCRIBE_MESSAGES = gql`
  ${MESSAGE_FRAGMENT}
  subscription SubscribeMessages($where: PhoneMessageSubscriptionWhereInput!) {
    phoneMessages(where: $where){
      event
      node {
        ...PhoneMessage
      }
    }
  }
`;
export const ASSIGN_LEAD_TO_MESSAGE = gql`
  ${DOCUMENT}
  mutation UpdatePhoneMessageLead($id:ID!, $fields:UpdatePhoneMessageFieldsInput!){
    updatePhoneMessage(input: {
      id: $id,
      fields: $fields
    }) {
      phoneMessage {
        id
        scope {
          ... on Node {
            id
          }
          ...Document
        }
      }
    }
  }
`;
