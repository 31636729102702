import React                              from "react";
import { EmailsField as BaseEmailsField } from "@relcu/ui";
import { EmailsFieldProps }               from "@relcu/ui";
import { IChoiceField }                   from "../../../../types/ISchemas";
import { useSchemaField }                 from "../../useSchemaField";

export const EmailsField = React.memo<Omit<EmailsFieldProps, "options">>(function EmailsField(props) {
  const { options } = useSchemaField<IChoiceField>("EmailAddress", "type");
  return (
    <BaseEmailsField {...props} options={options}/>
  );
});
