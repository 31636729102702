import { useMemo }                  from "react";
import { useMutation }              from "@apollo/client";
import { gql }                      from "@apollo/client";
import { useNavigate }              from "@relcu/react-router";
import { useWindowDimensions }      from "@relcu/ui";
import { useSource }                from "@relcu/ui";
import { useModal }                 from "@relcu/ui";
import { getObjectPath }            from "../../../../utils/layoutUtils";
import { getField }                 from "../../../../utils/schemaUtils";
import { usePermissions }           from "../../../AccessControl";
import { DirectMailSearchModal }    from "../../../Modal/DirectMailSearchModal/DIrectMailSearchModal";
import { SchemaDialog }             from "../../Dialog/SchemaDialog";
import { useMailApi }               from "../../../useMailApi";

export function useLeadHeader() {
  const [modal, modalHolder] = useModal(SchemaDialog);
  const [recordModal, recordModalHolder] = useModal(DirectMailSearchModal)
  const { $object: node, $viewer } = useSource();
  const { defaultMailbox, nodeEmail, createDraftMail, createDraftMailData } = useMailApi(node);
  const navigate = useNavigate();
  const { canUpdate } = usePermissions(node);
  const { states, transitions, actions } = getField("Lead", "leadStatus");
  const windowDimensions = useWindowDimensions();
  const small = useMemo(() => {
    return windowDimensions.width < (window.__CONFIG__.f9 ? 1800 : 1600);
  }, [windowDimensions]);
  const [updateStatus] = useMutation(UPDATE_LEAD_STATUS, {});
  const api = {
    modalHolder,
    recordModalHolder,
    node,
    states,
    canUpdate,
    status: node.leadStatus.status,
    isDuplicate: !!node.duplicateOf,
    get showMore() {
      return small;
    },
    get canExport(){
      return this.canUpdate && !node.relcuLoan?.objectId
    },
    get availableActions() {
      return Object.keys(transitions[ api.status ] || {})?.map((actionId) => {
        return { value: actionId, label: actions[ actionId ].name };
      });
    },
    get canSendEmail() {
      return $viewer.identities?.find((c) => c?.provider == "microsoft")?.scope?.includes("Mail.Send");
    },
    get canContact() {
      return (node.__typename != "Lead" || !states[ node.leadStatus.status ]?.not_contact) && defaultMailbox && !createDraftMailData?.loading;
    },
    get readyToPullPricing() {
      return node.readyToPullPricing;
    },
    navigateToSms: () => {
      navigate(`/${getObjectPath(node)}/sms`);
    },
    navigateToEmail: async () => {
      const to = nodeEmail ? [nodeEmail] : [];
      if (canUpdate) {
        const { data: { createDraftEmail: { conversation: { objectId } } } } = await createDraftMail({
          from: defaultMailbox.address,
          to: to
        });
        navigate(`/lead/${node.objectId}/emails/${objectId}`);
      } else {
        navigate(`/lead/${node.objectId}/emails`);
      }
    },
    navigateToPricing: () => {
      navigate(`${getObjectPath(node)}/pricing`);
    },
    onAddTask: () => {
      modal({
        className: "Task",
        record: {
          subject: node
        }
      });
    },
    onSearchRecord: () => {
      recordModal({
        leadId: node.objectId,
        objectName: node.objectName
      });
    },
    onExportLead: () => {
      modal({
        className: "RelcuLoan",
        record: {

        }
      });
    },
    handleAction: ({ value }) => {
      const newStatus = transitions[ api.status ][ value ];
      return updateStatus({ variables: { id: node.id, status: newStatus, action: value } });
    }
  };
  return {
    ...api,
    availableActions: useMemo(() => api.availableActions, [node.leadStatus.status])
  };
}
export const UPDATE_LEAD_STATUS = gql`
  mutation UpdateStatus($id:ID!,$status:String,$action:String, $actionCount:Int){
    updateLead(input:{id:$id,fields:{leadStatus:{status: $status,action:$action, actionCount:$actionCount}}}){
      lead{
        id
        objectId
        leadStatus{
          status
          action
          actionCount
          currentStageActionCount
          currentStatusActionCount
          updatedAt
        }
        loanProposals
      }
    }
  }
`;
