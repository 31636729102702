import { useMemo }           from "react";
import React                 from "react";
import { FC }                from "react";
import { BoxComponentProps } from "../../Box";
import { BaseFieldProps }    from "../BaseField";
import { BaseArrayField }    from "../BaseField";

import PhoneNumbersEditField from "./PhoneNumbersEditField";
import PhoneNumbersReadField from "./PhoneNumbersReadField";

export type PhoneNumbersFieldProps = BaseFieldProps & BoxComponentProps & {
  options: { label: string, value: string }[]
};

export const PhoneNumbersField: FC<PhoneNumbersFieldProps> = React.memo(function PhoneNumbersField(props) {
  return (
    <BaseArrayField
      {...props}
      defaultValue={useMemo(() => [{ type: props.options[ 0 ]?.value }], [props.options])}
      readView={<PhoneNumbersReadField/>}
      editView={<PhoneNumbersEditField/>}
    />
  );
});
PhoneNumbersField.defaultProps = {
  options:[]
}
