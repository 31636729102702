import React                                 from "react";
import { FC }                                from "react";
import { useEffect }                         from "react";
import { useContext }                        from "react";
import { useCallback }                       from "react";
import { useApolloClient }                   from "@apollo/client";
import { gql }                               from "@apollo/client";
import { FormState }                         from "@relcu/form";
import { useLazyCondition }                  from "@relcu/ui";
import { useLazySummary }                    from "@relcu/ui";
import { useSource }                         from "@relcu/ui";
import { FormSpy, useForm }                  from "@relcu/form";
import { LoanEstimateOffer }                 from "../../../../../../graph/__types__/LoanEstimateOffer";
import { daysOfaYear }                       from "../../../../../../utils/helpers";
import { Proposal }                          from "../../Proposal";
import { loanAmountDetails }                 from "../../utils";
import { WHEN_DISCOUNT_POINT }               from "../offer.conditions";
import { WHEN_LENDER_CREDITS }               from "../offer.conditions";
import { PREPAID_COSTS_SUM_FIELDS }          from "../offer.conditions";
import { WHEN_IS_REFINANCE }                 from "../offer.conditions";
import { WHEN_IS_PURCHASE }                  from "../offer.conditions";
import { PURCHASE_SUM_FIELDS }               from "../offer.conditions";
import { REFINANCE_SUM_FIELDS }              from "../offer.conditions";
import { CLOSING_COSTS_SUM_FIELDS }          from "../offer.conditions";
import { MONTHLY_PAYMENT_SUM_FIELDS }        from "../offer.conditions";
import { OTHER_COSTS_SUM_FIELDS }            from "../offer.conditions";
import { CREDIT_REPORT_SUM_FIELDS }          from "../offer.conditions";
import { TITLE_FEE_SUM_FIELDS }              from "../offer.conditions";
import { OfferListener }                     from "../Offer/OfferListener";
import { TitleFeeListener }                  from "../Offer/TitleFeeListener";
import { LoanEstimateOfferClientValidState } from "./__types__/LoanEstimateOfferClientValidState";

export const RateCalculate: FC<{ fees: string[] }> = React.memo((props) => {
  const { fees } = props;
  const client = useApolloClient();
  const loanEstimate = useContext(Proposal.Context);
  const form = useForm<LoanEstimateOffer>();
  const { $settings: { pricing: settings } } = useSource();
  const evaluate = useLazyCondition();
  const calculateRefinanceSum = useLazySummary({ fields: REFINANCE_SUM_FIELDS });
  const calculatePurchaseSum = useLazySummary({ fields: PURCHASE_SUM_FIELDS });
  const calculateCashAtClosing = useLazySummary({ fields: CLOSING_COSTS_SUM_FIELDS });
  const calculatePrepaid = useLazySummary({
    fields: PREPAID_COSTS_SUM_FIELDS
  });
  const calculateTitleFee = useLazySummary({
    fields: TITLE_FEE_SUM_FIELDS
  });
  const calculateClosing = useLazySummary({
    fields: CLOSING_COSTS_SUM_FIELDS
  });
  const calculateCredit = useLazySummary({
    fields: CREDIT_REPORT_SUM_FIELDS
  });
  const calculateOther = useLazySummary({
    fields: OTHER_COSTS_SUM_FIELDS
  });
  const calculateMonthly = useLazySummary({
    fields: MONTHLY_PAYMENT_SUM_FIELDS
  });
  const whenLenderCredits = useLazyCondition({ conditions: WHEN_LENDER_CREDITS });
  const whenDiscountPoint = useLazyCondition({ conditions: WHEN_DISCOUNT_POINT });

  const calculate = useCallback((name?: string) => {
    let allValues = form.getState().values;

    const getPropertyTaxTotal = () => {
      return allValues.propertyTaxMonth * allValues.propertyTaxAmount;
    };

    const calculations = {
      withAppraisal: {
        get appraisalFee() {
          if ((allValues.isStreamLine && allValues.withAppraisal) || !allValues.isStreamLine) {
            return allValues.appraisalFee;
          }

          return null;
        }
      },
      isStreamLine: {
        get appraisalFee() {
          if ((allValues.isStreamLine && allValues.withAppraisal) || !allValues.isStreamLine) {
            return allValues.appraisalFee;
          }

          return null;
        }
      },
      price: {
        get points() {
          if (allValues.price == null) {
            return null;
          }
          return (100 - (allValues.price ?? 100));
        },
        get lenderCredits() {
          return whenLenderCredits({ source: allValues }).apply ? Math.round((Number(allValues.totalLoanAmount) * Math.abs(Number(this.points)))) / 100 : 0;
        },
        get discountPoints() {
          return whenDiscountPoint({ source: allValues }).apply ? Math.round((Number(allValues.totalLoanAmount) * Math.abs(Number(this.points)))) / 100 : 0;
        }
      },
      rate: {
        get prepaidInterestAmount() {
          return (allValues.rate ? allValues.totalLoanAmount / 100 * allValues.rate : 0) / daysOfaYear(new Date().getFullYear());
        },
        get prepaidInterestTotal() {
          return allValues.prepaidInterestDays * this.prepaidInterestAmount;
        },
        get pi() {
          if (allValues.pricingEngine == "manual") {
            const loanAmount = allValues.totalLoanAmount;
            const loanTerm = Number(allValues.loanTerm);
            const monthlyRate = Number(allValues.rate) / 1200;

            if (monthlyRate == 0 || isNaN(monthlyRate)) {
              return 0;
            }

            return Number(((loanAmount * monthlyRate * Math.pow(1 + monthlyRate, loanTerm)) / (Math.pow(1 + monthlyRate, loanTerm) - 1)).toFixed(2));
          } else {
            return allValues.pi;
          }
        }
      },
      prepaidInterestDays: {
        get prepaidInterestTotal() {
          return allValues.prepaidInterestDays * allValues.prepaidInterestAmount;
        }
      },
      prepaidInterestAmount: {
        get prepaidInterestTotal() {
          return allValues.prepaidInterestDays * allValues.prepaidInterestAmount;
        }
      },
      propertyTaxMonth: {
        get propertyTaxTotal() {
          return getPropertyTaxTotal();
        }
      },
      propertyTaxAmount: {
        get propertyTaxTotal() {
          return getPropertyTaxTotal();
        }
      },
      propertyInsurancePrepaidMonth: {
        get propertyInsurancePrepaidTotal() {
          return allValues.propertyInsurancePrepaidMonth * allValues.propertyInsurancePrepaidAmount;
        }
      },
      propertyInsurancePrepaidAmount: {
        get propertyInsurancePrepaidTotal() {
          return allValues.propertyInsurancePrepaidMonth * allValues.propertyInsurancePrepaidAmount;
        }
      }
      // propertyInsuranceYearly: {
      //   get propertyInsurancePrepaidAmount() {
      //     if (loanEstimate.loanPurpose != "purchase") {
      //         return allValues.propertyInsurancePrepaidAmount
      //     }
      //
      //     if (!allValues.propertyInsuranceYearly) {
      //       return 0;
      //     }
      //
      //     return allValues.propertyInsuranceYearly / 12;
      //   },
      //   get propertyInsurancePrepaidTotal () {
      //     return allValues.propertyInsurancePrepaidMonth * this.propertyInsurancePrepaidAmount
      //   }
      // }
    } as Record<keyof LoanEstimateOffer, any>;

    if (!name) {
      Object.keys(calculations).forEach(field => {
        const changed = Object.assign({}, calculations[ field ]);
        Object.keys(changed).forEach(field => {
          form.change(field as keyof LoanEstimateOffer, changed[ field ]);
        });
        allValues = form.getState().values;
      });
    } else {
      const changed = Object.assign({}, calculations[ name ]);
      Object.keys(changed).forEach(field => {
        form.change(field as keyof LoanEstimateOffer, changed[ field ]);
      });
    }
  }, [loanEstimate, form]);

  const calculateCashToClose = useCallback(({ values }) => {
    const { total, amount } = loanAmountDetails(loanEstimate, values, settings);
    const totalCashAtClosing = calculateCashAtClosing({
      $pmiProducts: settings.pmiProducts,
      ...loanEstimate,
      ...values
    });
    let closing;

    if (evaluate({ conditions: WHEN_IS_PURCHASE, source: { ...values, ...loanEstimate } }).apply) {
      closing = calculatePurchaseSum({
        purchaseValue: values?.propertyValue,
        totalLoanAmount: -total,
        loanAmount: total - amount,
        totalClosingCosts: totalCashAtClosing
      });
    } else if (evaluate({ conditions: WHEN_IS_REFINANCE, source: { ...values, ...loanEstimate } }).apply) {
      closing = calculateRefinanceSum({
        currentMortgageBalance: values?.currentMortgageBalance,
        totalLoanAmount: -total,
        loanAmount: total - amount,
        totalClosingCosts: totalCashAtClosing
      });
    }
    form.change("cashToClose", Number(closing.toFixed(2)));
  }, [loanEstimate, settings, fees, form]);
  const calculatePrepaidCosts = useCallback(({ values }) => {
    const prepaidCosts = calculatePrepaid({
      loanPurpose: loanEstimate.loanPurpose,
      addedFees: fees,
      ...values
    });
    form.change("prepaidCostTotal", Number(prepaidCosts.toFixed(2)));
  }, [loanEstimate, fees]);
  const calculateTitleFees = useCallback(({ values }) => {
    const titleFees = calculateTitleFee(values);
    form.change("titleFeeTotal", Number(titleFees.toFixed(2)));
  }, [loanEstimate, fees]);
  const calculateClosingCosts = useCallback(({ values }) => {
    const closingCosts = calculateClosing({
      ...values,
      ...loanEstimate,
      $pmiProducts: settings.pmiProducts
    });
    form.change("closingCostTotal", Number(closingCosts.toFixed(2)));
  }, [loanEstimate, fees, settings]);
  const calculateOtherClosing = useCallback(({ values }) => {
    const otherCost = calculateOther({
      ...values,
      ...loanEstimate,
      lenderCredits: -values.lenderCredits,
      sellerConcession: -values.sellerConcession,
      earnestMoneyPaid: -values.earnestMoneyPaid,
      $closingFees: settings.closingFees,
      addedFees: fees,
      propertyType: loanEstimate.propertyType,
      $state: loanEstimate.propertyState,
      $surveyFeeStates: settings.closingFees.surveyFeeStates,
      $attorneyFeeStates: settings.closingFees.attorneyFeeStates,
      $docReviewFeeStates: settings.closingFees.docReviewFeeStates,
      $pestInspectionFeeStates: settings.closingFees.pestInspectionFeeStates
    });

    form.change("otherCostTotal", Number(otherCost.toFixed(2)));
  }, [loanEstimate, fees, settings]);
  const calculateCreditReport = useCallback(({ values }) => {
    const creditReport = calculateCredit({
      ...values,
      $mersFeeStates: settings.closingFees.mersFeeStates,
      $state: loanEstimate.propertyState
    });
    form.change("creditReportTotal", Number(creditReport.toFixed(2)));
  }, [loanEstimate, fees]);
  const calculateMonthlyPayment = useCallback(({ values }) => {
    const monthlyPayment = calculateMonthly({ ...values, $pmiProducts: settings.pmiProducts });

    form.change("monthlyPaymentTotal", Number(monthlyPayment.toFixed(2)));
  }, [loanEstimate, fees, settings]);

  useEffect(() => {
    calculate();
  }, []);

  const handleValidStateChange = ({ dirtySinceLastSubmit, dirty, values, valid, invalid }: FormState<any>) => {
    queueMicrotask(() => {
      client.writeFragment<LoanEstimateOfferClientValidState>({
        fragment: LOAN_ESTIMATE_OFFER_CLIENT_VALID_STATE,
        data: {
          __typename: "LoanEstimateOffer",
          isValid: valid
        },
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: values.id })
      });
    });
  };

  const handleTitleFeeChange = () => {
    form.change("titleCompany", "custom");
  };

  return (
    <>
      <OfferListener
        names={[
          "prepaidInterestDays",
          "prepaidInterestAmount",
          "propertyInsurancePrepaidMonth",
          "propertyInsurancePrepaidAmount",
          "propertyInsuranceYearly",
          "propertyTaxMonth",
          "propertyTaxAmount",
          "rate",
          "price",
          "withAppraisal",
          "isStreamLine"
        ]}
        onChange={calculate}
      />
      <TitleFeeListener
        names={[
          "titleInsurance",
          "recordingCharges",
          "ownersTitle",
          "transferTax",
          "settlementFee",
          "lendersTitle"
        ]}
        onChange={handleTitleFeeChange}
      />
      <FormSpy
        subscription={{ valid: true, error: true, errors: true, dirty: true, dirtySinceLastSubmit: true, values: true }}
        onChange={handleValidStateChange}/>
      <FormSpy
        subscription={{ values: true, modified: true }}
        onChange={calculateCashToClose}/>
      <FormSpy
        subscription={{ values: true, modified: true }}
        onChange={calculatePrepaidCosts}/>
      <FormSpy
        subscription={{ values: true }}
        onChange={calculateTitleFees}/>
      <FormSpy
        subscription={{ values: true, modified: true }}
        onChange={calculateClosingCosts}/>
      <FormSpy
        subscription={{ values: true }}
        onChange={calculateCreditReport}/>
      <FormSpy
        subscription={{ values: true, modified: true }}
        onChange={calculateOtherClosing}/>
      <FormSpy
        subscription={{ values: true }}
        onChange={calculateMonthlyPayment}/>
    </>
  );
});
export const LOAN_ESTIMATE_OFFER_CLIENT_VALID_STATE = gql`
  fragment LoanEstimateOfferClientValidState on LoanEstimateOffer {
    isValid @client
  }
`;
