import { ReactiveVar }    from "@apollo/client/cache/inmemory/reactiveVars";
import { classNames }     from "@relcu/ui";
import { Box }            from "@relcu/ui";
import React              from "react";
import { FC }             from "react";
import { SyntheticEvent } from "react";
import { useRef }         from "react";
import { useEffect }      from "react";
import { TitleFee }       from "../__types__/TitleFee";
import { Rate }           from "./Rate/Rate";
import { RateList }       from "./RateList";
import { ResultsProps }   from "./Results";
import { ResultsClasses } from "./ResultsClasses";
import { useRates }       from "./useRates";

type Lead = any
export interface Pmi {
  option: string;
  premium: number;
  providerId: string;
  providerName: string;
  rate: number;
  ref: number;
}
export const RatesContext = React.createContext<{
  titleFeeResult: ResultsProps["titleFeeResult"]
  pmiResult: ResultsProps["pmi"],
  sticky: number,
  best: number,
  setSticky(id: number),
  rateStick(e: SyntheticEvent, active: boolean, index: number)
  appraisalFeeValue: number
  condoCertificateFee: number
  keepDirtyOnReinitialize: boolean
  getAppraisalFeeValue(),
  selected: {
    title: {
      selectedTitleFeeIdVar: ReactiveVar<string>
      customTitleFeeVar: ReactiveVar<TitleFee>
    },
    pmi: {
      selectedPmiVar: ReactiveVar<any>
    }
  }
}>(null);
export const RatesProvider: FC<ResultsProps> = React.memo(function RatesProvider(props) {
  const { rates, defaultSelectedRef, sticky, active, best, ...rest } = useRates(props);
  const containerRef = useRef(null);

  useEffect(() => {
    if (defaultSelectedRef?.current && sticky == null) {
      defaultSelectedRef.current.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" });
    }
    if (defaultSelectedRef?.current && sticky) {
      containerRef.current.scrollTo({ top: 0 });
    }
  }, [rates, sticky]);

  return (
    <RatesContext.Provider value={{ ...rest, sticky, best }}>
      <div className={classNames(ResultsClasses.List, { [ ResultsClasses.Stick ]: sticky != null })} ref={containerRef}>
        {
          rates.map(rate => {
            //todo change index
            let rateProps = rate.index == active ? { ref: defaultSelectedRef } : {};
            return (
              <Rate
                {...rateProps}
                rate={rate}
                key={rate.index}
                index={rate.index}
                best={rate.index == best}
                sticky={rate.index == sticky}
                active={rate.index == active}
              />
            );
          })
        }
      </div>

    </RatesContext.Provider>
  );
});
