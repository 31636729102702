import React                                                    from "react";
import { PhoneNumbersField as BasePhoneNumbersField }           from "@relcu/ui";
import { PhoneNumbersFieldProps as BasePhoneNumbersFieldProps } from "@relcu/ui";
import { IChoiceField }                                         from "../../../../types/ISchemas";
import { useSchemaField }                                       from "../../useSchemaField";

export type PhoneNumbersFieldProps = Omit<BasePhoneNumbersFieldProps, "options">
export const PhoneNumbersField = React.memo<PhoneNumbersFieldProps>(function PhoneNumbersField(props) {
  const { options } = useSchemaField<IChoiceField>("PhoneNumber", "type");
  return <BasePhoneNumbersField {...props} options={options}/>;
});
