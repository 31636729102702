import { gql }                           from "@apollo/client";
import { useAlert }                      from "@relcu/ui";
import { useModal }                      from "@relcu/ui";
import { useSource }                     from "@relcu/ui";
import { useCallback }                   from "react";
import { useQuery }                      from "@apollo/client";
import { useNavigate }                   from "@relcu/react-router";
import { MessageDraft }                  from "../../../reactiveVars";
import { messageDraftsVar }              from "../../../reactiveVars";
import { getField }                      from "../../../utils/schemaUtils";
import { GetMessageTemplatesVariables }  from "../../__types__/GetMessageTemplates";
import { GetMessageTemplates }           from "../../__types__/GetMessageTemplates";
import { usePermissions }                from "../../AccessControl";
import { GET_MESSAGE_TEMPLATES }         from "../../operations.graphql";
import { useMailApi }                    from "../../useMailApi";
import { useMessageTemplateQueryParams } from "../../useMessageTemplateGqlParams";
import { GetMailLPTemplates }            from "./__types__/GetMailLPTemplates";
import { PdfModal }                      from "./index";

export function usePreviewLoanProposal(nodeId: string) {
  const navigate = useNavigate();
  const { states } = getField("Lead", "leadStatus");
  const lpTemplateQuery = useQuery<GetMailLPTemplates>(GET_MAIL_LP_TEMPLATES);
  const loanProposalTemplate = lpTemplateQuery.data?.emailTemplates?.edges?.at(0)?.node?.objectId;
  // const [render] = useMailRenderer();
  const [open, modalContext] = useModal(PdfModal);
  const { $viewer, $object } = useSource();
  const { defaultMailbox, mailBoxes, nodeEmail, createDraftMail } = useMailApi($object);
  const fromMail = defaultMailbox?.address;
  const { error } = useAlert();
  const where = useMessageTemplateQueryParams({
    type: "sms",
    considerOwner: true,
    isLoanProposal: true
  });
  const { data: { phoneMessageOwnerTemplates: { edges: ownerEdges = [] } = {}, phoneMessageNoOwnerTemplates: { edges: noOwnerEdges = [] } = {} } = {} } = useQuery<GetMessageTemplates, GetMessageTemplatesVariables>(GET_MESSAGE_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      ...where,
      first: 1
    }
  });
  const { canUpdate } = usePermissions($object);
  const templates = [...ownerEdges, ...noOwnerEdges];
  let doNotContact = !!states[ $object.leadStatus.status ]?.not_contact || !canUpdate;
  return {
    modalContext,
    preview: useCallback((options: {
      file: any,
      proposalId?: string,
      image?: any,
      loanEstimateId?: string,
      onCancel?: () => void
    }) => {
      const { file, image, onCancel, ...proposalOrEstimateId } = options;
      if (file?.url) {
        const { destroy } = open({
          onCancel,
          url: file.url,
          actions: [
            {
              children: "SEND VIA MMS",
              disabled: doNotContact,
              onClick: (event) => {
                const drafts = messageDraftsVar();
                const { id, ...attachment } = image || file;
                const draft: MessageDraft = {
                  attachment: [{ attachment }],
                  ...proposalOrEstimateId
                };
                const template = templates[ 0 ];
                if (template) {
                  draft.template = {
                    label: template.node.title,
                    value: template.node.text,
                    objectId: template.node.objectId
                  };
                }
                messageDraftsVar({
                  ...drafts,
                  [ nodeId ]: draft
                });
                destroy();
                setTimeout(() => {
                  navigate(`/lead/${nodeId}/sms`);
                });

              }
            },
            {
              children: "SEND VIA EMAIL",
              disabled: (doNotContact || !nodeEmail || !fromMail),
              onClick: async () => {
                // let html = "";
                // let text = "";
                // let subject = "Loan Proposal";
                // if (loanProposalTemplate) {
                //   const result = await render({
                //     variables: {
                //       input: {
                //         to: [nodeEmail],
                //         from: $viewer.objectId,
                //         subject: "Loan Proposal",
                //         proposal: options.proposalId || options.loanEstimateId,
                //         scope: $object.objectId,
                //         templateId: loanProposalTemplate
                //       }
                //     }
                //   });
                //   subject = result.data.renderMail.subject;
                //   html = result.data.renderMail.html;
                //   text = result.data.renderMail.text;
                // }
                const { data: { createDraftEmail: { conversation: { objectId } } } } = await createDraftMail({
                  subject: "Loan Proposal",
                  from: fromMail,
                  to: [nodeEmail],
                  loanEstimateId: options.loanEstimateId,
                  loanProposalId: options.proposalId,
                  html: "",
                  text: "",
                  scope: $object.id,
                  template: loanProposalTemplate,
                  attachments: [{
                    url: file.url,
                    objectId: file.objectId,
                    mimeType: file.mimeType,
                    name: file.name
                  }]
                });
                destroy();
                setTimeout(() => {
                  navigate(`/lead/${nodeId}/emails?from=${objectId}`);
                });
              }
            }
          ]
        });
      } else {
        error("Oops. Looks like something wrong with pdf file.");
      }

    }, [navigate, nodeId, templates, $viewer, doNotContact])
  };
}

const GET_MAIL_LP_TEMPLATES = gql`
  query GetMailLPTemplates {
    emailTemplates(where: {applyTo: {equalTo: "loanProposal"}, enabled: {equalTo: true}},first: 1,order: [createdAt_DESC]){
      edges {
        node {
          id
          objectId
          objectIcon
          objectName
          enabled
          content
          lead
          loanProposal
          title
          subject
          owner {
            id
            objectIcon
            objectId
            objectName
          }
        }
      }
    }
  }
`;
