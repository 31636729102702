import React                        from "react";
import { FC }                       from "react";
import { HTMLAttributes }           from "react";
import { DeepPartial }              from "@apollo/client/utilities";
import { classNames }               from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { BulkHeader }               from "../../../../Section/BulkSection/BulkHeader";
import { BulkItemClasses }          from "../../../MailView/BulkItemContent/BulItemClasses";
import { MailSection }              from "../../../MailView/MailSection/MailSection";
import { MailMessagesClasses }      from "../../../MailView/Message/MailMessages";
import { PhoneMessageConversation } from "../../__types__/PhoneMessageConversation";
import { BulkItemPreview }          from "./BulkItemPreview";
import { BulkItemStatistics }       from "./BulkItemStatistics";
import { useBulkContent }           from "./useBulkContent";
import "../../../MailView/BulkItemContent/bulk-item.css";

export interface BulkItemSectionProps extends HTMLAttributes<HTMLDivElement> {
  conversation: DeepPartial<PhoneMessageConversation>;
}

export const BulkItemContent: FC<BulkItemSectionProps> = React.memo(function BulkItemContent(props) {
  const { className, conversation, ...p } = props;
  const {
    lastSender,
    bulkConversation,
    onBehalf,
    stats,
    phoneMessages,
    slide,
    count,
    onCancelAll,
    pageCount,
    columns,
    actions,
    selectedItem,
    page,
    onPage,
    onFilter,
    status,
    cancelLoading,
    loading,
    skip
  } = useBulkContent(conversation);

  return <Box container direction={"column"} gap={"XXS"}
              className={classNames(className, BulkItemClasses.BulkItemSection)} flex={1} {...p}>
    <BulkHeader name={lastSender.party.objectName} subject={"Bulk SMS"}/>
    <MailSection container direction={"column"} flex={1} className={MailMessagesClasses.Body} gap={"XS"}>
      {
        !selectedItem
          ? <BulkItemStatistics onBehalf={onBehalf} statistics={stats} status={status} onCancelAll={onCancelAll}
                                onFilter={onFilter} actions={actions} data={phoneMessages} columns={columns}
                                page={page} onPage={onPage} pageCount={pageCount} count={count}
                                cancelLoading={cancelLoading} loading={loading}/>
          :
          <BulkItemPreview
            skip={skip}
            slide={slide}
            count={count}
            status={status}
            selectedItem={selectedItem}
            data={phoneMessages}/>
      }
    </MailSection>
  </Box>;
});


