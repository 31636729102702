import React                      from "react";
import { useCallback }            from "react";
import { ButtonSizes }            from "../../..";
import { ButtonVariants }         from "../../..";
import { Button }                 from "../../..";
import { SwitchField }            from "../../..";
import { useBoxProps }            from "../../..";
import { GlobalClasses }          from "../../..";
import { FontIcon }               from "../../..";
import { Radio }                  from "../../..";
import { Label }                  from "../../..";
import { Box }                    from "../../..";
import { classNames }             from "../../..";
import { BaseFieldClasses }       from "../BaseField";
import { useConsumeArrayField }   from "../BaseField";
import { ChoiceField }            from "../ChoiceField";
import { PhoneNumberField }       from "../PhoneNumberField";
import { PhoneNumbersFieldProps } from "./PhoneNumbersField";

export const PhoneNumbersEditField = React.memo(function PhoneNumbersEditField(props) {
  const {
    fields,
    input: { className, label, required, defaultValue, fullSize = true, options, ...phoneNumberProps },
    meta
  } = useConsumeArrayField<PhoneNumbersFieldProps>();
  const { touched, error } = meta;
  const classes = classNames(BaseFieldClasses.MultiField, {
    [ BaseFieldClasses.FullSize ]: fullSize
  }, className);

  const changeDefault = useCallback((changedIndex, changedName) => {
    fields.forEach((name: string, index: number) => {
      let value = fields.value[ index ];
      if (changedName === name) {
        fields.update(index, {
          ...value,
          isPrimary: true
        });
      } else if ((changedName !== name) && value.isPrimary) {
        fields.update(index, {
          ...value,
          isPrimary: false
        });
      }
    });
  }, [fields]);

  const removeItem = useCallback((index) => {
    if (fields.value[ index ].isPrimary) {
      fields.update(0, {
        ...fields.value[ 0 ],
        isPrimary: true
      });
    }
    fields.remove(index);
  }, [fields]);

  return (
    <Box className={classes} container {...useBoxProps(phoneNumberProps)}>
      <Box container direction={"column"} flex={1} gap={"XXXS"}>
        {
          label &&
          <Label required={required} mLeft>
            {label}
          </Label>
        }
        {
          fields.map((name, index) => {
            return <Box container justify={"space-between"} gap={"XS"} alignItems={"center"} key={name}>
              <Box container justify={"space-between"} gap={"XS"} alignItems={"center"} flexBasis={"50%"}>
                <ChoiceField flexGrow={1} name={`${name}.type`} defaultValue={options[ 0 ]?.value} flexShrink={0}
                             options={options} width={200}/>
                <PhoneNumberField
                  flexGrow={1}
                  name={`${name}.number`}
                  value={null}
                  options={[]}
                  message={""}
                  optionKey={"objectId"}
                  optionLabel={"objectName"}
                />
              </Box>
              <Box container gap={"XS"} flexBasis={"50%"}>
                <Box container direction={"column"}>
                  <SwitchField type={"Checkbox"} placeholder={"Opt-out (Call)"} name={`${name}.callOptOut`}
                               flexShrink={0}/>
                  <SwitchField type={"Checkbox"} placeholder={"Opt-out (SMS)"} name={`${name}.smsOptOut`} flexShrink={0}/>
                </Box>
                <Radio label="Default" name={"default"} alignSelf={"center"}
                       checked={fields.value[ index ].isPrimary ?? false}
                       onChange={(e) => changeDefault(index, name)}/>
                <Box flex={1}/>
                <FontIcon type={"delete"} alignSelf={"center"} onClick={() => removeItem(index)}
                          className={BaseFieldClasses.RemoveAction}/>
              </Box>
            </Box>;
          })
        }
        {touched && !!error && typeof error === "string" &&
        <Box className={classNames(GlobalClasses.Message, GlobalClasses.Error)}>
          {error}
        </Box>
        }
      </Box>
      <Box flexShrink={0} container gap={"XXS"} justify={"end"} className={BaseFieldClasses.FieldActions}>
        <Button icon={"add"} onClick={() => fields.push({ type: options[ 0 ]?.value })} variant={ButtonVariants.Ghost} size={ButtonSizes.Small}>ADD PHONE</Button>
      </Box>
    </Box>
  );
});

export default PhoneNumbersEditField;
