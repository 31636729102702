import React            from "react";
import { gql }          from "@apollo/client";
import { Subscription } from "@apollo/client/react/components";
import { GET_SETTINGS } from "../../graph/operations.graphql";

const SETTINGS = gql`
  subscription SettingsSubscription{
    settings:settings_v2
  }
`;

export function SettingsSubscription() {
  return (
    <Subscription subscription={SETTINGS} onData={({client, data})=>{
      client.writeQuery({
        query: GET_SETTINGS,
        data: {
          settings: data.data.settings.node
        }
      })
      return data
    }}/>
  )
}
